.casehistory_backtoprofile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
        border-radius: 15px !important;
    }
}
.credits-container {
    .credit-row {
        display: flex;
        justify-content: space-between;
        border-bottom: 0.5px solid $gray-300;
        padding-top: 10px;
        .job {
            font-size: 1rem;
            font-weight: 300;
        }
        .name {
            font-weight: 600;
            font-size: 1rem;
        }
    }
}
.ask-agency-card {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .button {
        margin-top: 10px;
        border-radius: 15px !important;
    }
}
.testimonials-container {
    .credit-row {
        border-radius: 10px;
        border: 0.5px solid $gray-300;
        display: flex;
        flex-direction: row;
        padding: 20px;
        margin: 25px 0;
        .profile {
            display: flex;
            flex-direction: row;
            .image-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
                img {
                    border-radius: 100%;
                    width: 100%;
                    aspect-ratio: 1/1;
                    padding: 20px;
                }
            }
            .name {
                font-weight: 600;
                font-size: 1.8rem;
            }
            .job {
                font-size: 1rem;
                font-weight: 100;
            }
            .agency {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
        .description {
            display: flex;
            flex-direction: row;
            .quote {
                line-height: 1;
                font-size: 4vw;
                font-weight: 600;
            }
            .text {
                padding-left: 15px;
                padding-right: 20px;
                font-size: 1rem;
                font-weight: 100;
            }
        }
    }
}
