.component.review-list {
    &.tile-vertical {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
        padding-top: 30px;
    }
    .tile.vertical.review {
        background: white;
        border-radius: 10px;
        border:0.5px solid $gray-300;
        margin: 0px;
        cursor: pointer;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding: 15px 30px;
        div.cover {
            position: relative;
            width: 70%;
            h3 {
                margin: 10px 0px;
                font-size: 24px;
                font-weight: 700;
            }
            h4 {
                margin: 10px 0px;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                display: inline-block;
                border: 0.5px solid #c4c4c4;
                padding: 0 20px;
                border-radius: 13px;
                span {
                    margin-left: 15px;
                    padding-left: 15px;
                    border-left: 0.5px solid $gray-200;
                    font-weight: 100;
                }
            }
            p.description {
                font-weight: 300;
            }

            .reviewwrapper.general {
                position: absolute;
                top: 9px;
                right: 0;
                width: 150px;
                margin: 0;
            }
            .project {
                span {
                    font-weight: 100;
                    color: $gray-500;
                    width: 150px;
                    display: inline-block;
                }
            }
        }
        div.content {
            .subject {
                display: flex;
                justify-content: space-between;
            }
            ul.reviewdetail {
                padding: 0;
                margin: 0;
                list-style-type: none;
                width: 100%;

                li {
                    border-radius: 5px;
                    border: 0.5px solid $gray-200;
                    margin: 5px 0px;
                    padding: 0 5px 0 20px;
                    position: relative;
                    height: 50px;
                    line-height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-weight: 100;
                    }
                    .reviewwrapper {
                        margin: 0;
                        width: 150px;
                        transform: scale(0.8);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .component.review-list {
        .tile.vertical.review {
            
            flex-direction: column;
            div.cover {
                width: 100%;
                .reviewwrapper {
                    scale:0.9;
                }
            }
        }
    }
}
