.component.project-modal {
    background-color: $black;
    width: 100vw;
    height: 100vh;
    padding: 50px;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    cursor:zoom-out;

    .cover {
        height: 100%;
        position: relative;
        .image,
        video  {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 5px;
            img{
                width: 100%;
            }
        }

    }

    &::after {
        content: "\f00d";
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        top:20px;
        right: 20px;
        width: 40px;
        height: 40px;
        color:$white;
        border-radius: 50%;
        border:1px solid $white;
        text-align: center;
        line-height: 40px;
    }
}

@media only screen and (max-width : 767px) {
    .component.project-modal {
        overflow-y: scroll;
        padding: 20px;
        .cover {
            display: block;
            margin-top:50px;

            .image {
                width: 100%;
                margin-bottom: 50px;
            }
            .description {
                width: 100%;
                height: auto;
                overflow-y: auto;
                padding-left: 0px;
                h4 {
                    font-size: 2rem;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 1.2rem;
                    font-weight: 100;
                }
            }
        }
    }
}