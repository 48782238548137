@mixin centerelementpadding($hwrapper, $helement){
    padding: ($hwrapper - $helement)*0.5;
}
@mixin centerelement($hwrapper, $helement){
    margin-top: ($hwrapper - $helement)*0.5;
}
@mixin ellipsis() {
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
}
a {
    color:$accent;
    :hover {
        text-decoration: none;
    }
}
.clearer {
    clear:both;
}
h1 {
    &:after {
        content:".";
        font-weight: 900;
        color: $accent;
        font-family: serif;
        animation: pulse 5s infinite;
    }
}
a.button,
div.button,
button.button,
input[type="submit"].button {
    display: block;
    height: 60px;
    line-height: 60px;
    font-size: 17px;
    max-width: 270px;
    width: 100%;
    text-align: center;
    color:$black;
    border:0.5px solid $black;
    border-radius: 10px;
    cursor:pointer;
    &.white {
        color:$white;
        border-color: $white;
    }
    &.micro {
        border:0.5px solid $black;
        font-size: 14px;
        line-height: 30px;
        height: 30px;
        max-width: 170px;
        border-radius: 5px;
        font-weight: 300;
    }
    i {
        color:$accent;
        margin-right: 10px;
    }
    &:hover {
        color:$white;
        background: $accent;
        border-color: $accent;
        text-decoration: none;
        i {
            color:$white;
        }
    }
    &.action {
        height:45px;
        border-radius: 22.5px;
        background:$white;
        border:0.5px solid $gray-900;
        line-height: 45px;
        color:$gray-900;
        font-size: 14px;
        margin-left:10px;
        max-width:initial;
        width: auto;
        display: inline-block;
        padding:0 15px;
        &.primary {
            background: $maincolor;
            border-color: $maincolor;
            color:$white;
            padding:0 30px;
            font-size: 16px;
        }
        &.secondary {
            background: $white;
            border-color: $white;
            color:$gray-900;
        }
        &.maincolor {
            background: $maincolor;
            border-color: $maincolor;
            color:$white;
        }
        &.dimmed {
            background: $gray-200;
            border-color: $gray-200;
            color:$white;
        }
        &.micro {
            font-size: 12px;
            height: 30px;
            border-radius: 15px;
            line-height: 30px;
        }
        &:hover {
            color:$white;
            background: $accent;
            border-color: $accent;
            text-decoration: none;
        }
        &.disabled {
            background: $gray-200;
            border-color: $gray-200;
            color:$white;
            pointer-events:none;
        }
    }
    &.huge {
        line-height: 66px;
        height: 66px;
        border-radius:33px
    }
}
.rating {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;  
    justify-content: right;
  
    .rating-text {
        font-size: 20px;
        font-weight: 300;
        span {
            font-size: 35px;
            font-weight: 500;
        }
    }
    #stars-box {
        font-size: 30px;
    }
    .stars-container {
        position: relative;
        display: inline-block;
        color: transparent;
    }
    .stars-container:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "★★★★★";
        color: lightgray;
    }
    .stars-container:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "★★★★★";
        color: gold;
        overflow: hidden;
    }
}
.stickywrapper {
    position: relative;
    .sticky {
        position: sticky;
        top:0;
    }
}
.showall {
    text-align:right;
}
.sectionopener {
    font-style: normal;
    h1 {
        font-weight: 600;
        font-size: 40px;
        line-height: 1.1em;
    }
    h2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 1.1em;
        color:$maincolor;
        text-align:center;
        &.small {
            font-weight: 700;
            font-size:24px;
            margin-bottom:10px;
            margin-top:60px;
            text-align: left;
        }
    }
    h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 1.1em;
    }
    h5 {
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        text-transform: uppercase;
        margin:0;
    }
    p {
        display:block;
        padding:0 20%;
        text-align:center;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        &.small {
            font-size: 16px;
            margin-bottom: 0px;
            font-weight: 100;
            text-align: left;
            padding: 0;
        }
    }
}

ul.filterset {
    display: block;
    overflow:hidden;
    list-style: none;
    padding:0;
    margin:15px 0 0;
    font-size: 12px;
    &.open {
        li {
            display: block;
            &.filtersactivator {
                &:after {
                    color:$accent;
                }
            }
        }
        margin-bottom:40px;
    }
    li {
        padding:0px 32px 0px 5px;
        margin: 0px 5px 10px 0px;
        background: $white;
        border-radius:5px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        height: 33px;
        position:relative;
        display: none;
        float: right;
        &.filtersactivator {
            display:block;
            cursor:pointer;
            box-shadow: none;
            margin-right:0;
            position: absolute;
            top: -40px;
            padding: 0;
            right: 15px;
            width: 30px;
            &:after {
                content:"\f0b0";
                font-size: 20px;
                font-family: "Font Awesome 5 Pro";
                font-weight: 100;
                color:$gray-700;
                position: absolute;
                top: 0;
                right: 0;
                line-height: 30px;
                text-align:right;
            }
            &:hover {
                &:after {
                    color:$accent;
                }
            }
            &.active {
                &:after {
                    color:$accent;
                }
            }
        }
        label {
            margin:0px;
            font-weight: 300;
            height: 33px;
            line-height: 33px;
            color:$black;
        }
        select {
            border:none;
            background: $white;
            font-weight: 300;
            height: 33px;
            line-height: 33px;
            padding:0;
            color:$black;
        }
        div.checkbox {
            input[type="checkbox"] {
                display: none;
            }
            &:before {
                content:'';
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                top: 0px;
                left: 0px;
                font-size: 15px;
                line-height: 15px;
                cursor: pointer;
            }
            position:absolute;
            top:9px;
            left:10px;
            width: 15px;
            height: 15px;
        }
        input[type="checkbox"] {
            position:absolute;
            top:9px;
            left:10px;
            width: 15px;
            height: 15px;
        }
        &:after {
            content:'';
            position:absolute;
            top:1px;
            right:1px;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-repeat: no-repeat;
            background-size: 70%;
            background-position: center;
        }
        &.reviews {
            &:after {
                background-image: url(/images/icons/reviews.svg);
            }
        }
        &.projects {
            &:after {
                background-image: url(/images/icons/projects.svg);
            }
        }
        &.employees {
            &:after {
                background-image: url(/images/icons/employees.svg);
            }
        }
        &.industry {
            &:after {
                background-image: url(/images/icons/industry.svg);
            }
        }
        &.minsize {
            &:after {
                background-image: url(/images/icons/minsize.svg);
            }
        }
        &.city {
            &:after {
                background-image: url(/images/icons/city.svg);
            }
        }

        &.active {
            padding-left: 32px;
            div.checkbox {
                &:before {
                    content:'\f2d3';
                }
                &:hover {
                    &:before {
                        color:$accent;
                    }
                }
            }
        }
    }
}

ul.breadcrumb {
    background: transparent;
    border-radius: 0;
    padding:50px 0px 10px;
    margin:0;
    li {
        font-size: 16px;
        line-height: 31px;
        margin-right: 15px;
        a {
            font-weight: 300;
            color:$accent
        }
        &:after {
            content:'\f054';
            font-family: 'Font Awesome 5 Pro';
            font-size: 9px;
            line-height: 31px;
            margin-left: 15px;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.logowrapper {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding:0;
    border:0.3em solid $white;
    margin:28px 0px 0px 0px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.premium {
        .logofallback {
            line-height: 73px;
        }
    }

    .logofallback {
        width:100%;
        height:100%;
        line-height: 83px;
        text-align:center;
        font-size:40px;
        font-weight:700;
        border-radius: 50%;
    }
}

.togglepreferred {
    width: 30px;
    height: 30px;
    cursor:pointer;
    &:hover {
        text-decoration: none;
        &:after {
            color:$accent;
        }
    }
    &:after {
        content:'\f004';
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        color:$gray-200;
        font-size: 24px;
    }

    &.ispreferred {
        &:after {
            font-weight: 700;
            color:$accent;
        }
    }
}
.morewrapper {
    width:45px;
    height:45px;
    display: block;
    border-radius: 50%;
    background-color: $maincolor;
    position: relative;
    cursor:pointer;
    line-height: 45px;
    &:after {
        content:'...';
        font-size: 30px;
        line-height: 28px;
        width: 45px;
        height: 45px;
        color:$white;
        text-align: center;
        display: block;
        position: absolute;
    }
    &:hover {
        border-radius: 50% 50% 0 0;
        background-color: $white;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        z-index: 2;
        &:after {
            color:$gray-700;
        }
        ul {
            display: inline-block;
        }
    }
    ul {
        display: none;
        z-index: 2;
        list-style-type: none;
        position: absolute;
        background: $white;
        border-radius: 12px 0px 12px 12px;
        width: auto;
        height: auto;
        padding: 5px 30px;
        right: 0;
        top: 80%;
        text-align: left;
        box-shadow: 0 10px 10px rgba(0,0,0,0.1);
        li {
            border-bottom: 0.5px solid $gray-200;
            padding:10px 10px 10px 40px;
            color:$accent;
            a {
                font-size: 16px;
                color:$gray-900;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
}
.search {
    input[type="text"] {
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        padding: 0 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        background: #FFFFFF;
        border-radius: 18px;
        border: 0px;
        &::placeholder {
            font-weight: 100;
        }
    }
    input[type="submit"] {
        position: absolute;
        top: 4px;
        right: 20px;
        height: 52px;
        width: 53px;
        border: none;
        border-radius: 17px;
        background-color: #FF6864;
        background-image: url(/images/icons/search.svg);
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;
    }
}
span.count {
    font-weight: 300;
    font-size: 0.8em;
    padding-left: 10px;
}
.reviewwrapper {
    position: relative;
    height: 30px;
    width: 200px;
    margin:10px auto 5px;
    .base {
        position: absolute;
        top:0;
        left:0;
        width: 150px;
        height: 30px;
        background-image: url(/images/icons/trustgray.svg);
        background-repeat: repeat-x;
        background-size: contain;
    }
    .evaluation {
        position: absolute;
        top:0;
        left:0;
        width: 150px;
        height: 30px;
        background-image: url(/images/icons/trustcolor.svg);
        background-repeat: repeat-x;
        background-size: contain;
    }
    .evaluators {
        position: absolute;
        top:0;
        right:0;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
    }
}

div.hamburger {
    width: 40px;
    height: 40px;

    @include centerelement($hnav, 40px);

    .icon {
        width: 30px;
        height: 30px;
        position: relative;
        transition: .1s;
        cursor: pointer;
        display: inline-block;
        font-size: 24px;
        line-height: 40px;
        color:$maincolor;
    }

}

.smartbriefactivator {
    background-color: $maincolor;
    background-image: linear-gradient(45deg, $maincolor, $accent);
    text-align: center;
    color: $white;
    padding: 60px 30px;
    h2 {
        font-weight: 700;
        font-size: 3em;
        line-height: 1em;
    }
    p {
        font-weight: 300;
        font-size: 1.4em;
        line-height: 2em;
    }
    div.button.action.primary {
        margin: 0;
        background-color: $white;
        border-color: $white;
        color: $maincolor;
        box-shadow: 0 0 20px #FFFFFF;
        &:hover {
            border-color: $accent;
            background-color: $accent;
            color:$white;
            box-shadow: 0 0 20px #FF6864CC;
        }
    }
}