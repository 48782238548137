
.component.testimonial-list {
    &.tile-horizontal {
    }
    width: 100%;
    .tile.horizontal.testimonial {
        margin:20px 0px;
        padding: 15px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        grid-auto-row:300px;
        div.image {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            overflow: hidden;
            grid-column: 1 ;
            grid-row: 1;
            justify-self: end;
            img {
                width: auto;
                height:100%;
            }
        }
        div.profile {
            grid-column: 2 / span 2 ;
            grid-row: 1;
            h4 {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                margin:0px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top:28px;
            }
            h5 {
                font-size: 14px;
                line-height: 18px;
                font-weight: 300;
                height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        div.content {
            text-align:center;
            grid-column: 1 / span 3;
            grid-row: 2;
            font-size: 18px;
            line-height: 30px;
            font-weight: 300;
            margin: 0;
            padding:40px 40px 0;
            &:before {
                content:'“';
                font-family:serif;
                font-weight:700;
                color:$accent;
                font-size:50px;
                line-height: 0px;
            }
            &:after {
                content:'„';
                font-family:serif;
                font-weight:700;
                color:$accent;
                font-size:50px;
                line-height: 0px;
            }
        }
    }

}
