.component.business-intelligence-graph {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    box-shadow:0 0 30px rgba(0,0,0,0.1);
    border-radius:30px;
    margin-top:60px;
    overflow:hidden;
    .cell {
        justify-self: stretch;
        padding:30px;
        &.map {
            background:$white;
            grid-column: 1 ;
            grid-row: 1 / span 2;
            .title {
                display:inline-block;
                float:left;
            }
            .selectorwrapper {
                display:none;//inline-block;
                float:right;
                margin-bottom:30px;
            }
            .asset.map {
                top: 50%;
                position: relative;
                transform: translateY(-50%);
                svg {
                    text {
                        font-family:$font-family-sans-serif;
                        font-weight:700;
                        letter-spacing: -1px;
                        text-align:center;
                    }
                    #level1 {
                        g {
                            cursor:pointer;
                        }
                    }
                    #level2 {
                        > g > g {
                            cursor:alias;
                            text {
                                font-weight:300;
                                letter-spacing:0;
                            }
                        }
                    }
                }
            }
        }
        &.searched {
            background:$gray-100;
            grid-column: 2;
            grid-row: 1;
        }
        &.offered {
            background:$gray-300;
            grid-column: 2;
            grid-row: 2;
        }
        &.searched,
        &.offered {
            .title {
                margin-bottom:20px;
            }
            .graph {
                display:grid;
                grid-template-columns: repeat(2, 1fr);
                .name {
                    font-weight:300;
                    padding:5px 0px;
                    justify-self: stretch;
                    grid-column: 1;
                    text-align:right;
                    padding-right:20px;
                    white-space:nowrap;
                }
                .valuewrapper {
                    padding:5px 0px;
                    justify-self: stretch;
                    grid-column: 2;
                    position:relative;
                    .barbottom {
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        height:12px;
                        border-radius:6px;
                        background:$gray-400;
                        width:100%;
                    }
                    .bar {
                        position:absolute;
                        top:50%;
                        left:2px;
                        transform:translateY(-50%);
                        height:8px;
                        border-radius:4px;
                        min-width:4px;
                        background:$accent;
                        z-index:1;
                    }
                    .value {
                        position:absolute;
                        display:none;
                        background:$accent;
                        top:50%;
                        transform:translateY(-50%);
                        left:6px;
                        width:auto;
                        height:20px;
                        font-size:14px;
                        line-height:20px;
                        color:$white;
                        border-radius:10px;
                        z-index:2;
                        padding:0 10px;
                    }
                }
            }
        }

        &.offered {
            .graph {
                .valuewrapper {
                    .bar {
                        background: $maincolor;
                    }

                    .value {
                        background: $maincolor;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 991px) {
    .component.business-intelligence-graph {
        .cell {
            &.map {
                .asset.map {
                    top: 0;
                    transform: none;
                }
            }
        }
    }
}