@import 'variables';

.swiper {
    padding-bottom:30px !important;

    .swiper-horizontal > .swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom:-6px;
        .swiper-pagination-bullet-active {
            background-color: $accent;
        }
    }
}

.swiper-button-next,
.swiper-button-prev {
    color:$gray-300;
    width: 24px;
    height: 24px;
    top:55%;
    &.swiper-button-disabled {
        color:$gray-200;
    }
    &:after {
        font-size: 20px;
    }
}

.swiper-button-next {
    right:-10px;
}

.swiper-button-prev {
    left:-10px;
}


@media only screen and (max-width : 767px) {
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
}