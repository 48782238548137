.component.video-recorder {
    position:relative;
    button {
    }

    button:last-of-type {
        margin: 0;
    }

    p.borderBelow {
        margin: 0 0 20px 0;
        padding: 0 0 20px 0;
    }

    a.record {
        position: absolute;
        top: 12px;
        z-index:4;
    }

    video {
        vertical-align: top;
        --width: 100%;
        width: var(--width);
        height: calc(var(--width) * 0.5625);
        border-radius:20px;
        margin: 0 0 20px 0;
        &.recorded {
            position:absolute;
            border:10px solid $white;
            top:40px;
            right:40px;
            --width: 30%;
            width: var(--width);
            height: calc(var(--width) * 1.015);
            border-radius:20px;
            display:none;
        }
    }
}
