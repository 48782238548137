.component.agency-products-editor {
    position:relative;
    
    .agency-products-editor-element {
        margin-bottom: 40px;
        overflow: hidden;
        height: auto;
        border-bottom: 0.5px solid #C4C4C4;
        padding: 0px 0 40px;

        .form-element {
            textarea {
                height: 120px !important;
            }
            .preview {
                display: block;
                position: relative;
                img {
                    width: auto;
                    height: auto;
                    max-height: 100%;
                    position: absolute;
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                }
                .dz-preview {
                    height: fit-content;
                    display: flex;
                    align-items: center;
                    .dz-image {
                        margin-right: 10px;
                        img {
                            transform: translateY(0);
                            height: 65px;
                        }
                    }
                    .dz-details {
                        text-align: left;
                    }
                    .dz-success-mark, .dz-error-mark {
                        display: none;
                    }
                    .dz-remove {
                        margin-left: 10px;
                    }
                }
            }
        }

        &:last-child {
            border: none;
            padding: 0px;
        }
    }
}



@media only screen and (max-width: 1200px) {
}