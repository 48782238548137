.component.leadform {
    position: relative;

    >* {
        transition: 1s;
    }

    &.big {
        form.closed {
            padding: 50px;

            .title {
                font-size: 2em;
            }
        }
    }

    &.dark {
        form.closed {
            background: $maincolor;

            .title {
                color: $white;
            }

            .form-intro-answers {
                label {
                    color: $white;

                    &:hover {
                        color: $accent;
                    }
                }

                .selected {
                    label:hover {
                        color: $accent-100;
                    }
                }
            }
        }
    }

    &.colored {
        form.closed {
            background: $accent;

            .title {
                color: $white;
            }

            .form-intro-answers label {
                color: $white;

                &:hover {
                    color: $accent;
                }
            }
        }
    }

    &.animated {
        form.closed {
            animation: highlight 5s infinite;
        }
    }

    &.illustrated {
        form.closed {
            padding: 50px;
            padding-left: 220px;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 25px;
                left: 10px;
                width: 180px;
                height: 150px;
                background-image: url(/images/bkg-touchpoint-3.svg);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    &.static {
        form {
            position: static;
        }
    }

    form {
        position: absolute;
        top: -400px;
        padding: 30px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        display: block;
        border-radius: 10px;
        background: $white;
        z-index: 100;
        overflow: hidden;

        >* {
            padding: 10px 0;
            margin: 0;
        }

        .form-intro-element {
            padding: 0;
            clear: both;
            margin-bottom: 30px;

            .title {
                margin-top: 0;
                margin-bottom: 20px;
            }
        }

        .form-body {
            padding: 0;
            clear: both;
            max-height: 5000px;

            &.no-max-height {
                max-height: unset;
            }

            >* {
                padding: 10px 0;
                margin: 0;
            }

            .form-body-cover {
                margin: -40px -30px 30px -30px;
                padding: 30px;
                background-color: $accent;
                width: calc(100% + 60px);

                >.title {
                    color: $white;
                    font-size: 2em;
                }

                >.introduction {
                    color: $white;
                    font-size: 1.5em;
                }
            }

            .progress {
                position: relative;
                background-color: $accent-100;
                height: 20px;
                border-radius: 6px;
                z-index: 1;

                .progressbar {
                    border: 3px solid $accent-100;
                    background-color: $green;
                    position: absolute;
                    height: 20px;
                    border-radius: 6px;
                    top: 0;
                    left: 0;
                    width: 0;
                }

                .progresslabel {
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    font-size: 12px;
                    color: $accent;
                    z-index: 2;
                    width: 100%;
                }
            }

            .title {
                padding-bottom: 0px;
            }

            fieldset {
                >* {
                    padding: 10px 0;
                    margin: 0;
                }

                &.chat {
                    position: relative;
                    padding-bottom: 170px;

                    .message {
                        label {
                            background-color: $accent;
                            color: $white;
                            border-bottom: 30px;
                            border-radius: 0px 20px 20px 20px;
                            max-width: 80%;
                            font-size: 20px;
                            line-height: 26px;
                            font-weight: 300;
                            padding: 40px 30px 15px;
                            position: relative;

                            &:before {
                                content: "Qualifier";
                                position: absolute;
                                top: 10px;
                                left: 30px;
                                font-size: 12px;
                                font-weight: 700;
                                color: $accent-100;
                            }
                        }

                        .tapositioner {
                            textarea {
                                resize: none;
                                height: 150px;
                                font-size: 20px;
                                line-height: 26px;
                            }
                        }

                        &.past {
                            .tapositioner {
                                position: relative;
                                border-radius: 20px 0px 20px 20px;
                                background-color: greenyellow;
                                margin-top: 30px;
                                padding: 40px 30px 15px;
                                float: right;
                                width: 80%;

                                &:before {
                                    content: "Tu";
                                    position: absolute;
                                    top: 14px;
                                    left: 30px;
                                    font-size: 12px;
                                    font-weight: 700;
                                    color: darkgreen;
                                }

                                textarea {
                                    position: static;
                                    height: auto;
                                    background: transparent;
                                    border: 0;
                                    padding: 0;
                                }

                                .closer {
                                    display: none;
                                }
                            }
                        }

                        &.current {
                            .tapositioner {
                                textarea {
                                    position: absolute;
                                    bottom: 0;
                                    width: calc(100% - 100px);
                                }

                                .closer {
                                    position: absolute;
                                    color: $white;
                                    line-height: 30px;
                                    bottom: -27px;
                                    width: calc(100% - 100px);
                                    border-radius: 0px 0px 5px 5px;
                                    background-color: $accent;
                                    padding: 0 20px;
                                    cursor: pointer;
                                    font-weight: 300;
                                }
                            }
                        }

                        &.future {
                            display: none;
                        }

                        &.loader {
                            background-color: $accent;
                            display: inline-block;
                            position: relative;
                            width: 180px;
                            height: 80px;
                            border-radius: 0 20px 20px 20px;

                            div {
                                position: absolute;
                                top: 33px;
                                width: 13px;
                                height: 13px;
                                border-radius: 50%;
                                background: $white;
                                animation-timing-function: cubic-bezier(0, 1, 1, 0);

                                &:nth-child(1) {
                                    left: 58px;
                                    animation: lds-ellipsis1 0.6s infinite;
                                }

                                &:nth-child(2) {
                                    left: 58px;
                                    animation: lds-ellipsis2 0.6s infinite;
                                }

                                &:nth-child(3) {
                                    left: 82px;
                                    animation: lds-ellipsis2 0.6s infinite;
                                }

                                &:nth-child(4) {
                                    left: 106px;
                                    animation: lds-ellipsis3 0.6s infinite;
                                }
                            }
                        }

                        @keyframes lds-ellipsis1 {
                            0% {
                                transform: scale(0);
                            }

                            100% {
                                transform: scale(1);
                            }
                        }

                        @keyframes lds-ellipsis3 {
                            0% {
                                transform: scale(1);
                            }

                            100% {
                                transform: scale(0);
                            }
                        }

                        @keyframes lds-ellipsis2 {
                            0% {
                                transform: translate(0, 0);
                            }

                            100% {
                                transform: translate(24px, 0);
                            }
                        }

                    }

                    .nextchat {
                        z-index: 5;
                        background-color: $accent;
                        padding: 5px 20px;
                        border-radius: 20px;
                        color: $white;
                        position: absolute;
                        bottom: 50px;
                        right: 0;
                        cursor: pointer;

                        &.disabled {
                            color: $accent-100;
                        }
                    }
                }

                .stepmovement {
                    margin-top: 30px;
                    display: block;
                    position: relative;
                    text-align: center;
                    clear: both;

                    .timer {
                        font-size: 12px;
                        font-weight: 200;
                    }

                    .button.action {
                        margin-top: 0px;
                        font-weight: 100;


                        &[data-role="previous"] {
                            float: left;
                            margin-top: 20px;
                        }

                        &[data-role="next"] {
                            background-color: $maincolor;
                            border-color: $maincolor;
                            color: $white;
                            font-size: 22px;
                            line-height: 50px;
                            height: 50px;
                            width: 100%;
                            border-radius: 5px;
                            font-weight: 700;
                            margin-top: 20px;

                            &:hover {
                                background-color: $accent;
                                border-color: $accent;
                            }
                        }

                        &:disabled {
                            opacity: 0.3;
                        }
                    }
                }
            }
        }

        &.closed {
            .form-body {
                max-height: 0;
                overflow-y: hidden;
                overflow-x: visible;
            }

            .form-intro-element {
                margin-bottom: 0;
            }
        }

        .title {
            font-weight: 700;
            font-size: 20px;
            color: $accent;

            &:empty {
                padding: 0;
            }
        }

        .introduction {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;

            &:empty {
                padding: 0;
            }
        }

        .thankyou {
            font-weight: 700;
        }

        .form-element {

            &.checkbox,
            &.radio {
                input {
                    width: auto;
                    margin-right: 10px;
                }

                >label {
                    font-size: 15px;
                    line-height: 16px;
                    padding-right: 0;
                    margin: 0;
                    line-height: 24px;
                }

                .option-group {
                    input {
                        width: 50px;
                        margin-right: 0px;
                    }

                    .option-element {
                        display: flex;
                        width: 100%;
                        height: 50px;
                        margin-bottom: 5px;
                        border: 0.5px solid $gray-300;
                        border-radius: 5px;
                        justify-content: center;
                        align-items: center;

                        input {
                            margin-top: 0px;
                        }

                        >label {
                            line-height: 20px;
                            font-size: 18px;
                            font-weight: 300;
                            cursor: pointer;
                        }

                        &:hover {
                            background-color: $gray-100;
                        }
                    }
                }
            }
        }

        input[type="submit"].button.action {
            margin-top: 20px;
            margin-left: 0;

            &:disabled {
                opacity: 0.5;
                cursor: wait;
            }
        }

        margin-bottom: 40px;
    }

    &.open-modal {
        form {
            .form-body-modal {
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                background-color: rgba(255, 255, 255, 0.9);
                z-index: 1000;

                .form-body {
                    width: 50vw;
                    height: auto;
                    max-height: 90vh;
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background-color: white;
                    border-radius: 5px;
                    padding: 30px;
                    border: 0.5px solid $gray-300
                }
            }

            &.closed {
                .form-body-modal {
                    display: none;
                }

                &.done {
                    .form-body-modal {
                        display: block;
                        position: static;
                        transform: none;
                        width: 100%;
                        height: auto;

                        .form-body {
                            display: block;
                            position: static;
                            transform: none;
                            width: 100%;
                            height: auto;
                            padding: 0;
                            border: 0;
                        }
                    }
                }
            }
        }

        &.dynamic {
            form {
                .form-body-modal {
                    .form-body-cover {
                        border-radius: 5px 5px 0 0;
                    }
                }

                .form-element {
                    width: 100%;
                }

                &.closed {
                    &.done {

                        .form-body-modal,
                        .form-body {
                            background: transparent;
                        }
                    }
                }
            }

            &.dark {
                form {
                    &.closed {
                        &.done {

                            .form-body-modal,
                            .form-body {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .ctarationale {
        display: none;
        position: absolute;
        background-color: $accent;
        color: $white;
        border-radius: 5px;
        padding: 30px;
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
        cursor: pointer;
        transition: 2s;

        span {
            font-weight: 100;
            font-size: 16px;
            display: block;
        }

        &:after {
            content: "";
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
            display: block;
            position: absolute;
            background-color: $accent;
        }
    }
}

@media only screen and (max-width: 991px) {
    .component.leadform {
        form {
            position: relative;
            top: auto;
        }
    }
}

@media only screen and (max-width: 767px) {
    .component.leadform {
        &.open-modal {
            form {
                margin-bottom: 0;

                .form-body-modal {
                    .form-body {
                        width: 80vw;
                    }
                }
            }
        }

        form {
            .form-body {

                .form-body-cover {
                    >.title {
                        font-size: 1.4em;
                    }

                    >.introduction {
                        font-size: 1.2em;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width: 575px) {
    .component.leadform {
        &.illustrated {
            form.closed {
                padding: 20px;
                padding-top: 160px;

                &:after {
                    left: 50%;
                    transform: translateX(-50%);
                    width: 120px;
                    height: 120px;
                }
            }
        }

        &.open-modal {
            position: fixed;
            bottom: 15px;
            left: 4vw;
            margin: 0 !important;
            width: 92vw;
            z-index: 2;

            form {
                margin-bottom: 0;
                padding: 20px;

                .form-intro-element {
                    padding: 0 !important;

                    h4 {
                        font-size: 18px;
                        margin-bottom: 15px;
                    }
                }

                .form-body-modal {
                    .form-body {
                        width: 95vw;

                        fieldset.anagraphical {
                            .form-element {
                                padding: 10px 5px;
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}