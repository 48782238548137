.component.brief-viewer {
    position:relative;
    .brief-viewer-element {
        margin-bottom:40px;
        overflow: hidden;
        height: auto;
        border:1px solid $gray-200;
        border-radius:10px;

        .brief-short,
        .brief-full {
            > div {
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                padding:5px 20px;
                position:relative;
                &:after {
                    font-size:10px;
                    color:$gray-200;
                    font-weight:100;
                    position:absolute;
                    top:8px;
                    left:8px;
                    line-height:10px;
                }
            }
        }
        .brief-short {
            display:grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0px;
            grid-auto-rows: minmax(60px, auto);
            cursor:pointer;
            > div {
                line-height:60px;
            }
            .name {
                grid-column: 1/3;
                grid-row: 1;
                font-size:22px;
                font-weight:100;
                border-bottom:1px solid $gray-200;
                border-right:1px solid $gray-200;
                &:after {
                    content:"Titolo";
                }
            }
            .goal {
                grid-column: 3/4;
                grid-row: 1/2;
                border-bottom:1px solid $gray-200;
                &:after {
                    content:"Obiettivo";
                }
            }
            .due_at {
                grid-column: 1/2;
                grid-row: 2/3;
                border-right:1px solid $gray-200;
                &:after {
                    content:"Data consegna";
                }
            }
            .budget {
                grid-column: 2/3;
                grid-row: 2/3;
                border-right:1px solid $gray-200;
                &[data-type="corpo"] {
                    &:after {
                        content:"Budget a corpo";
                    }
                }
                &[data-type="tem"] {
                    &:after {
                        content:"Rate card oraria";
                    }
                }
            }
            .is_assigned {
                grid-column: 3;
                grid-row: 2;
                &:after {
                    content:"Agenzie proposte";
                }
            }
        }
        .brief-full {
            display:none;
            border-top:2px solid $gray-200;
            &.open {
                display:grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 0px;
                grid-auto-rows: minmax(60px, auto);
                cursor:pointer;
                > div {
                    line-height:60px;
                }
                .created_at {
                    grid-column: 1/2;
                    grid-row: 1;
                    font-weight:100;
                    border-bottom:1px solid $gray-200;
                    border-right:1px solid $gray-200;
                    &:after {
                        content:"Creato il";
                    }
                }
                .ratecard {
                    grid-column: 2/3;
                    grid-row: 1;
                    font-weight:100;
                    border-bottom:1px solid $gray-200;
                    border-right:1px solid $gray-200;
                    &:after {
                        content:"Rate card";
                    }
                }
                .province {
                    grid-column: 2 span;
                    grid-row: 1;
                    font-weight:100;
                    border-bottom:1px solid $gray-200;
                    &:after {
                        content:"Provincia";
                    }
                }
                .description {
                    grid-column: 1/4;
                    font-weight:100;
                    line-height:25px;
                    font-size:16px;
                    padding-top:25px;
                    min-height:70px;
                    border-bottom:1px solid $gray-200;
                    white-space: normal;
                    text-overflow: clip;
                    overflow: visible;
                    padding-bottom:30px;
                    &:after {
                        content:"Descrizione";
                    }
                    &:empty {
                        display:none;
                    }
                }
                .video {
                    grid-column: 1/4;
                    padding:0;
                    border-bottom:1px solid $gray-200;
                    line-height:0;
                    video {
                        width:100%;
                        height:auto;
                    }
                    &:after {
                        content:"Video descrizione";
                    }
                    &:empty {
                        display:none;
                    }
                }
                .media {
                    grid-column: 1/4;
                    font-weight:100;
                    line-height:22px;
                    font-size:16px;
                    padding-top:25px;
                    min-height:70px;
                    border-bottom:1px solid $gray-200;
                    &:after {
                        content:"Allegati";
                    }
                    &:empty {
                        display:none;
                    }
                }
                .sender {
                    grid-column: 1/4;
                    font-weight:100;
                    line-height:22px;
                    font-size:16px;
                    padding-top:25px;
                    min-height:70px;
                    border-bottom:1px solid $gray-200;
                    ul {
                        list-style-type:none;
                        padding:0;
                        li {
                            line-height:50px;
                            font-weight:500;
                            .label {
                                width: 90px;
                                font-size:12px;
                                margin-right: 10px;
                                font-weight: 100;
                                display: inline-block;
                            }
                            border-bottom:1px solid $gray-200;
                            &:last-child {
                                border-bottom:0;
                            }
                        }

                    }
                    &:after {
                        content:"Titolare di gara";
                    }
                    &:empty {
                        display:none;
                    }
                }
                .agencies {
                    grid-column: 1/4;
                    font-weight:100;
                    line-height:22px;
                    font-size:16px;
                    padding-top:25px;
                    min-height:70px;
                    border-bottom:1px solid $gray-200;
                    ul {
                        margin:0;
                        list-style-type: none;
                        padding:0;
                        li {
                            float:left;
                        }
                    }
                    &:after {
                        content:"Agenzie proposte";
                    }
                    &:empty {
                        display:none;
                    }
                }
                .interest {
                    grid-column: 1/4;
                    font-weight:100;
                    line-height:22px;
                    a.button.action,
                    input[type="submit"].button.action {
                        clear: both;
                        display: inline-block;
                        margin: 10px 0px;
                    }
                    input[type="submit"].button.action { 
                        display: block;
                    }
                    form {
                        label {
                            margin-top:20px;
                            display: block;
                        }
                        textarea {
                            width: 100%;
                            height: 200px;
                            background-color: $gray-100;
                            border-color: $gray-200;
                            border-radius:10px;
                            margin-bottom: 8px;
                            padding:30px;
                            font-weight: 200;
                            font-size:120%;
                        }
                    }
                }
            }
        }
    }
    &[data-type="receiver"] {
        .brief-viewer-element {
            .brief-short {

                .budget {
                    grid-column: 2/4;
                    border-right:none;
                }
            }
        }
    }
}
