
.component.industry-list {
    &.tile-horizontal {
    }
    
    width: 100%;
    .tile.horizontal.industry {
        background: white;
        border-radius: 10px;
        border:0.5px solid $gray-300;
        margin: 20px 0px;
        padding: 25px;
        // min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        aspect-ratio: 1/1;
        div.image {
            width: 100%;
            border-radius: 10px;
            // background-color: #f7f7f7;
            width: 130px;
            height: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            font-size: 36px;
            img {
                width: 130px;
            }
        }
        div.content {
            width: 100%;
            height: 108px;
            max-height: 108px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            // padding-left: 15px;
            // display: table-cell;
            // vertical-align: middle;
            h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                margin: 0px;
                text-align: center;
            }
        }
    }
}


@media only screen and (max-width : 1199px) {
    .component.industry-list {
        .tile.horizontal.industry {
            margin:20px 10px 0px 0px;
            h4 {
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width : 767px) {
    .component.industry-list {
        .tile.horizontal.industry {
            margin: 10px 2px 0px;
            h4 {
            }
        }
    }
}