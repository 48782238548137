// Variables
@import "variables";
// Vendors
@import "~bootstrap/scss/bootstrap";
@import "~jquery-ui/themes/base/all.css";
@import "fontawesome";
@import "swiper/css/bundle";
// Components
@import "component-search-bar";
@import "component-service-browser";
@import "component-service-table";
@import "component-agency-list";
@import "component-focus-list";
@import "component-resource-list";
@import "component-recipe-list";
@import "component-testimonial-list";
@import "component-certification-list";
@import "component-partnership-list";
@import "component-award-list";
@import "component-platform-list";
@import "component-industry-list";
@import "component-language-list";
@import "component-project-list";
@import "component-project-modal";
@import "component-review-list";
@import "component-video-recorder";
@import "component-advertising-zone";
@import "component-business-intelligence-graph";
@import "component-agency-details-editor";
@import "component-agency-projects-editor";
@import "component-agency-products-editor";
@import "component-brief-viewer";
@import "component-leadform";
@import "component-target-list";
@import "component-subscription-status";
@import "pages-brief-smart";
@import "casehistory";

// Misc
@import "elements";
@import "step";
@import "form-elements";
@import "swiper";

body {
    color: $black;
    background: $white;

    &[data-type="agency detail"] {
        header {
            background: $white;
        }
    }

    &.www {
        main section.jumbotron.noadvertising {
            //
            background-image: url(/images/bkg-www-3.svg);
        }
    }

    &.software {
        main section.jumbotron.noadvertising {
            background-image: url(/images/bkg-software-3.svg);
        }
    }

    &.marketing {
        main section.jumbotron.noadvertising {
            background-image: url(/images/bkg-marketing-3.svg);
        }
    }

    &.visual {
        main section.jumbotron.noadvertising {
            background-image: url(/images/bkg-visual-3.svg);
        }
    }

    &.informatica {
        main section.jumbotron.noadvertising {
            background-image: url(/images/bkg-informatica-3.svg);
        }
    }

    &.landing {
        &.onecolumn,
        &.onecolumnalternate {
            header.landing {
                .logo {
                    a {
                        //display: none;
                        text-align: center;

                        img {
                            margin-top: 26px;
                            height: 27px;
                        }
                    }

                    background-image: url(/images/qualifier.svg);
                    background-position: center;
                    background-size: 140px;
                    background-repeat: no-repeat;
                }
            }

            section.jumbotron {
                min-height: 350px;
                padding-top: 120px;

                .titlepositioner {
                    text-align: center;

                    h1 {
                        font-size: 2.7em;
                        line-height: 1.1em;
                        margin-bottom: 10px;
                    }

                    h2 {
                        font-size: 1.3em;
                        line-height: 1.1em;
                        margin-bottom: 0px;
                    }
                }
            }

            .component {
                &.leadform.dynamic {
                    margin-top: -80px;
                    text-align: left;

                    .form-element {
                        >label {
                            font-size: 16px;
                            margin: 10px 0px 5px;
                            font-family: Arial, Helvetica, sans-serif;
                        }

                        &.left {
                            padding-right:10px
                        }

                        &.right {
                            padding-left:10px;
                        }
                    }
                }
            }
        }

        &.onecolumnalternate {
            header.landing {
                .logo {
                    a {
                        text-align: left;
                    }
                    background-position: 15px 27px;
                    background-size: 140px;
                }
            }
            section.jumbotron {
                min-height: 600px;

                .titlepositioner {
                    text-align: left;
                    max-width: 66.6%;
                    h1 {
                        margin-top: 270px;
                        font-size: 3.5em;
                        line-height: 1.1em;
                        margin-bottom: 10px;
                    }

                    h2 {
                        font-size: 1.8em;
                        line-height: 1.2em;
                        margin-bottom: 0px;
                    }
                }
            }

            .component {
                &.leadform.dynamic {
                    margin-top: 50px;
                }
            }

            ul.linear {
                grid-template-columns: repeat(1, 1fr);
                li {
                    padding-bottom: 0;
                    strong.main {
                        color:$accent;
                        font-weight: 500;
                    }

                    span.important {
                        display: inline;
                        padding: 0 5px;
                        background-color: $accent-100;
                        position: static;
                        border-radius: 3px;
                    }

                    &.company {
                        &:after {
                            content:'\f1ad';
                            font-weight: 100;
                            font-size: 20px;
                        }
                    }

                    &.worker {
                        &:after {
                            content:'\f0c0';
                            font-weight: 100;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

* {
    transition: $transitionSpeed;
}

.accent {
    color: $accent;
}

.clearer {
    clear: both;
}

mark {
    color: $accent;
    background: transparent;
    padding: 0;
}

header {
    position: fixed;
    height: $hnav;
    background: transparent;

    &.scrolled {
        background: $white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }

    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;

    &.landing {
        position: absolute;
    }

    .container,
    .container-fluid {
        position: relative;
        height: $hnav;

        div.logo {
            height: $hnav;

            a {
                display: block;
                height: $hnav;

                img {
                    @include centerelement($hnav, 40px);
                    height: 40px;
                }
            }

            ul {
                height: auto;
                max-height: 0;
                transition: 0.5s;
                list-style-type: none;
                overflow: hidden;
                background: $white;
                border-radius: 30px 0 55px 5px;
                max-width: 185px;
                box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
                margin-top: -18px;

                li {
                    a {
                        height: auto;
                        font-weight: 100;
                        font-size: 12px;
                        line-height: 22px;
                    }
                }

                &::after {
                    font-family: "Font Awesome 5 Pro";
                    content: "\f078";
                    color: $accent;
                    position: absolute;
                    top: 26px;
                    right: 95px;
                }
            }

            &:hover {
                ul {
                    padding: 20px;
                    max-height: 200px;

                    &::before {
                        content: "";
                        background-color: $white;
                        position: absolute;
                        top: 20px;
                        right: 80px;
                        width: 42px;
                        height: 60px;
                        border-radius: 5px 5px 0 0;
                        box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.05);
                    }

                    &::after {
                        content: "\f077";
                    }
                }
            }
        }

        div.searchwrapper {
            $searchboxheight: 55px;

            .component.search-bar {
                margin-top: 0;

                form {
                    @include centerelement($hnav, $searchboxheight);
                    margin-right: 0;

                    input.searchBox {
                        height: $searchboxheight;
                        line-height: $searchboxheight;
                        font-size: 16px;

                        &::placeholder {
                            font-size: inherit;
                        }
                    }

                    input[type="submit"] {
                        position: absolute;
                        top: $searchboxheight * 0.1;
                        right: $searchboxheight * 0.1;
                        height: $searchboxheight - $searchboxheight * 0.2;
                        width: $searchboxheight - $searchboxheight * 0.2;
                    }

                    &.searching {
                        &:after {
                            font-size: 20px;
                            top: 13px;
                            right: 63px;
                        }
                    }
                }

                .searchResultWrapper {
                    width: 100%;
                    margin-right: 0;
                    padding-top: 30px;
                    top: 10px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

                    .ctabrief {
                        margin: -40px -30px 0 -30px;
                        padding: 50px 30px 10px 30px;

                        h3 {
                            width: 50%;
                        }

                        .button {
                            top: 43px;
                            right: 0;
                        }
                    }

                    ul {
                        li {
                            display: block;
                            width: 100%;

                            a {
                                text-align: left;
                                margin: 0;
                                padding: 0 3px;
                                line-height: 32px;

                                .logowrapper {
                                    position: relative;
                                    width: 26px;
                                    height: 26px;
                                    border-radius: 13px;
                                    margin: 3px 0;

                                    .logo {
                                        position: absolute;
                                        width: 26px;
                                        height: 26px;
                                        top: 0;
                                        left: 0;
                                        transform: none;
                                    }
                                }

                                .primary {
                                    padding-left: 10px;
                                }

                                .secondary {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: 12px;
                                    margin-left: 0;

                                    &.foundin {
                                        clear: both;
                                        transform: translateY(-10px);
                                        margin-left: 39px;
                                        display: block;
                                    }
                                }

                                &.premium {
                                    color: $black;
                                    font-weight: 500;
                                }
                            }
                        }
                    }

                    .suggest {
                        margin-top: 15px;
                    }
                }
            }
        }

        div.ctawrapper {
            >ul {
                display: flex;
                justify-content: flex-end;
                margin: 0;
                padding: 0;
                list-style-type: none;

                >li {
                    height: $hnav;

                    &.compact {
                        width: 210px;
                        overflow: hidden;
                    }

                    .button {
                        @include centerelement($hnav, 45px);

                        &.iconic {
                            aspect-ratio: 1 / 1;

                            i {
                                color: $white;
                                margin-right: 0;
                            }
                        }
                    }

                    &.dropdownwrapper {
                        .dropdownenabler {
                            border: none;
                            height: 45px;
                            line-height: 45px;
                            font-size: 14px;
                            display: inline-block;
                            width: auto;
                            height: 63px;
                            padding: 0 20px;
                            border-radius: 5px 5px 0 0;

                            &::after {
                                font-family: "Font Awesome 5 Pro";
                                content: "\f078";
                                color: $accent;
                                margin-left: 10px;
                            }
                        }

                        .dropdownscroller {
                            ul.dropdown {
                                display: none;
                                grid-auto-flow: column;
                                grid-auto-columns: 1fr;
                                gap: 30px;

                                margin: 0;
                                padding: 50px 30px;
                                list-style-type: none;
                                position: fixed;
                                left: 0;
                                top: $hnav;
                                background: $white;
                                width: 100%;
                                z-index: 5;
                                box-shadow: 0px 30px 20px rgba(0, 0, 0, 0.1);

                                li {
                                    position: relative;
                                    padding-bottom: 40px;

                                    &:first-child {
                                        padding-top: 40px;
                                        padding-bottom: 0;
                                    }

                                    h3 {
                                        font-weight: 700;
                                        color: $accent;
                                        font-size: 24px;
                                        padding-bottom: 20px;
                                        margin-bottom: 20px;
                                        position: relative;

                                        &::after {
                                            content: "";
                                            width: 50px;
                                            height: 3px;
                                            background-color: $accent;
                                            position: absolute;
                                            bottom: 0;
                                            left: 0;
                                        }
                                    }

                                    p {
                                        font-size: 15px;
                                        font-weight: 300;

                                        &.big {
                                            color: $accent;
                                            font-size: 20px;
                                            font-weight: 100;
                                            display: block;
                                            width: 80%;
                                            margin-bottom: 0;
                                        }
                                    }

                                    a.button {
                                        margin-left: 0;
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .dropdownenabler {
                                background-color: $white;
                                color: $black;

                                &::after {
                                    content: "\f077";
                                }
                            }

                            .dropdownscroller {
                                ul.dropdown {
                                    display: grid;
                                }
                            }
                        }
                    }


                    &.auth {
                        width: 70px;

                        a {
                            padding-left: 15px;

                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

main {
    margin-top: $hnav;

    section {
        border: 1px solid $temporaryborder;
        position: relative;

        &:after {
            position: absolute;
            top: 0;
            left: 10px;
            line-height: 14px;
            font-size: 10px;
            padding: 0 10px;
            background: $temporaryborder;
        }

        &.accented {
            &:before {
                content: "";
                position: absolute;
                background: $accent;
                width: 300px;
                height: 10px;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 5px;
            }
        }

        &.muted {
            background: $gray-100;
        }

        &.diagonal {
            @include diagonalbackground();
        }

        &.mutedcolor {
            background-image: linear-gradient(45deg, $maincolor-100, $accent-100);
        }

        &.dark {
            background: $gray-900;

            .sectionopener {
                color: $white;
            }
        }

        &.dotted {
            background-image: radial-gradient($accent 0.5px, transparent 0.5px),
                radial-gradient($accent 0.5px, $white 0.5px);
            background-size: 10px 10px;
            background-position: 0 0, 10px 10px;
        }

        &.inset {
            position: relative;

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 30px;
            }

            &:before {
                top: 0px;
                bottom: auto;
                background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
            }

            &:after {
                top: auto;
                bottom: 0px;
                background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
            }
        }

        &.jumbotron {
            border: none;
            margin-top: -$hnav;
            min-height: 720px + $hnav;
            margin-bottom: 0;
            border-radius: 0;
            padding-top: 80px + $hnav;
            padding-top: 90px + $hnav;
            border-radius: 0 0 50px 0;
            background: $gray-100;
            background-position: center right 100px;
            background-size: 30%;
            background-repeat: no-repeat;

            &.hasadvertising {
                //background-image: linear-gradient(45deg, $accent-100, $maincolor-100);
            }

            &.noadvertising {}

            h1 {
                font-style: normal;
                font-weight: 700;
                font-size: 3.2em;
                width: 90%;
                line-height: 1.2em;
                letter-spacing: -0.02em;
                color: $maincolor;
                text-shadow: 0 1px 1px $white;
            }

            .dida {
                width: 90%;
                font-weight: 300;
                font-size: 1.8em;
                line-height: 1.4em;
                margin-top: 60px;
            }

            .component.focus-list.search {
                margin-top: 60px;
                width: 70%;
            }

            .ctasupplier {
                margin-top: 30px;
                font-weight: 300;

                a {
                    color: $accent;
                    margin: 0px 7px;

                    i {
                        margin-right: 5px;
                    }
                }
            }

            .button {
                margin-left: 0;
            }

            ul {
                list-style-type: none;
                margin-top: 60px;
                margin-bottom: 60px;

                li {
                    width: 90%;
                    font-weight: 300;
                    font-size: 1.4em;
                    line-height: 2.4em;

                    i {
                        padding-right: 10px;
                    }

                    span {
                        padding-left: 10px;
                        font-weight: 700;
                    }
                }
            }

            .component.focus-list.tag {
                margin-top: 40px;
                margin-right: 100px;

                ul {
                    max-height: 90px;
                    overflow: hidden;
                }
            }

            .ctabrief {
                position: relative;
                margin-top: 40px;
                margin-right: 100px;
                padding: 30px;
                border: 0.5px solid $gray-400;
                border-radius: 20px;
                background: white;
                box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

                h3 {
                    font-size: 26px;
                    font-weight: 300;
                }

                p {
                    font-weight: 300;
                    margin: 0;
                }

                a.button {
                    background-color: $maincolor;
                    border-color: $maincolor;
                    color: $white;
                    position: absolute;
                    top: 30%;
                    right: 30px;

                    &:hover {
                        background-color: $accent;
                        border-color: $white;
                        color: $white;
                    }
                }

                &:hover {
                    border-color: $accent;
                }
            }
        }

        &.partners {
            border: 0px;
            height: 120px;
            margin-bottom: 50px;

            .container {
                background: #f9f9f9;
                border-radius: 0 0 15px 15px;
            }

            &.partnersandmedia {
                height: auto;

                .container {
                    background: transparent;
                }
            }

            ul {
                display: flex;
                list-style-type: none;
                padding: 0;
                margin: 0;
                position: relative;

                li {
                    display: inline-flex;
                    justify-content: center;
                    width: 50%;
                    height: 120px;
                    position: relative;
                    text-align: center;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        max-width: 70%;
                        max-height: 50px;
                        opacity: 0.5;
                        filter: grayscale(100%);

                        &:hover {
                            opacity: 1;
                            filter: grayscale(0%);
                        }
                    }

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: relative;
                    }
                }

                &:after {
                    content: "";
                    width: 100%;
                    border-top: 0.5px solid $accent;
                    font-size: 12px;
                    line-height: 25px;
                    color: $accent;
                    font-weight: 100;
                    text-align: center;
                    position: absolute;
                    display: none;
                }

                &:hover {
                    &:after {
                        display: block;
                    }
                }

                &.istitution {
                    &:after {
                        content: "Partner";
                    }
                }

                &.media {
                    &:after {
                        content: "Media";
                    }
                }
            }
        }

        &.whoweare {
            border: 0px;
            padding: 40px 0px 0;
            color: $white;
            text-align: center;

            .sectionopener {
                h2 {
                    color: $maincolor;
                    text-shadow: 0 0px 10px $white;
                }

                p {
                    color: $maincolor;
                }
            }

            .featurewrapper {

                .feature {
                    margin-top: 20px;
                    padding-top: 110px;
                    padding-bottom: 30px;
                    line-height: 20px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 300;
                    color: $maincolor;
                    border-radius: 5px;
                    border: 0.5px solid $white;

                    &:after {
                        content: "";
                        font-size: 30px;
                        font-family: "Font Awesome 5 Pro";
                        position: absolute;
                        font-weight: 300;
                        top: 30px;
                        left: 50%;
                        transform: translateX(-50%);
                        color: $white;
                        background: $accent;
                        width: 70px;
                        height: 70px;
                        border-radius: 35px;
                        line-height: 70px;
                        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
                    }

                    &:hover {
                        border-color: $gray-300;
                        text-decoration: none;
                    }

                    &.database {
                        &:after {
                            content: "\f233";
                        }
                    }

                    &.support {
                        &:after {
                            content: "\f672";
                        }
                    }

                    &.compare {
                        &:after {
                            content: "\f78c";
                        }
                    }

                    &.insight {
                        &:after {
                            content: "\f78a";
                        }
                    }

                    &.proposal {
                        &:after {
                            content: "\f4a2";
                        }
                    }

                    &.picto {
                        padding-top: 150px;

                        &:after {
                            content: "";
                            background-color: transparent;
                            background-repeat: no-repeat;
                            width: 100%;
                            display: block;
                            height: 140px;
                            background-position: center;
                            top: 0;
                        }

                        &.support {
                            &:after {
                                background-image: url(/images/bkg-learn-3.svg);
                            }
                        }

                        &.compare {
                            &:after {
                                background-image: url(/images/bkg-compare-3.svg);
                            }
                        }

                        &.proposal {
                            &:after {
                                background-image: url(/images/bkg-purchase-3.svg);
                            }
                        }

                        &.pain {
                            &:after {
                                background-image: url(/images/bkg-pain-3.svg);
                            }
                        }

                        &.watch {
                            &:after {
                                background-image: url(/images/bkg-watch-3.svg);
                            }
                        }
                    }

                    &.big {
                        font-size: 20px;
                        line-height: 26px;
                        font-weight: 100;
                        padding-top: 30px;
                        position: relative;

                        &:after {
                            content: "";
                            background-color: transparent;
                            background-repeat: no-repeat;
                            width: 220px;
                            display: block;
                            height: 220px;
                            background-position: center;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &.even {
                            &:after {
                                left: 0;
                                right: auto;
                            }

                            text-align: left;
                            padding-left: 240px;
                        }

                        &.odd {
                            &:after {
                                right: 0;
                                left: auto;
                            }

                            text-align: right;
                            padding-right: 240px;
                        }
                    }
                }
            }
        }

        &.quodigi {
            height: 300px;
            text-align: center;
            line-height: 300px;

            &:after {
                content: "quodigi";
            }
        }

        &.newspaper {
            border: 0px;
            padding: 0 0 40px;
        }

        &.target {
            border: 0px;
            padding: 0 0 40px;
        }

        &.services {
            border: 0px;
            padding: 80px 0px 60px;

            ul.component.focus-list {
                list-style-type: none;
                display: grid;
                gap: 20px;
                grid-template-columns: repeat(4, 1fr);
                margin: 0;
                margin-top: 40px;
                padding: 0;

                li {
                    .tile.vertical.focus a div.content {
                        h2 {
                            span {
                                font-size: 18px;
                                line-height: 24px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.companies {
            display: none;
            height: 300px;
            text-align: center;
            line-height: 300px;

            &:after {
                content: "companies";
            }
        }

        &.agencies {
            border: 0px;
            padding: 0px;
            position: relative;
        }

        &.testimonials {
            border: 0px;
            padding: 30px 0px;
            position: relative;
        }

        &.resources {
            border: 0px;
            padding: 100px 0px;
        }

        &.businessintelligence {
            border: 0px;
            padding: 60px 0px 100px;
        }

        &.touchpoint {
            border: 0px;
            padding: 0px;

            form.landingpage {
                position: static;
                margin-top: 30px;
                box-shadow: none;
                border: 0.5px solid $gray-300;
                border-radius: 5px;

                fieldset.anagraphical {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 30px;

                    .form-element {
                        padding: 10px 0;
                    }
                }
            }
        }

        &.mission {
            display: none;
            height: 400px;
            text-align: center;
            line-height: 400px;

            &:after {
                content: "mission";
            }
        }

        &.serp {
            border: 0px;

            .sectionopener {
                h2 {
                    font-weight: 700;
                    text-align: left;
                    font-size: 32px;
                }

                .count-results {
                    float: right;
                    margin-top: 16px;
                    font-size: 20px;
                    font-weight: 300;
                    opacity: 0;
                }
            }

            &.agency {
                .component.agency-list {
                    .tile.agency.horizontal {
                        margin-bottom: 40px;
                    }
                }
            }

            &.focus {
                padding-bottom: 60px;
            }

            &.recipe {
                padding-bottom: 60px;
            }

            &.noresult {
                padding-bottom: 60px;

                .container {
                    text-align: center;

                    .sectionopener {
                        margin-top: 40px;
                        padding: 100px;
                        border-radius: 10px;
                        background: $gray-100;

                        h2 {
                            text-align: center;
                        }
                    }
                }
            }
        }

        &.taxonomy {

            &.focus,
            &.specialization,
            &.newspaper {
                .container {
                    margin-bottom: 40px;
                }
            }

            &.focus,
            &.specialization,
            &.qa {
                &.title {
                    border: 0px;

                    .count-results {
                        font-weight: 300;
                        margin-left: 30px;
                    }

                    .description {
                        font-weight: 300;
                        line-height: 30px;
                        font-size: 18px;
                        padding-top: 30px;
                        margin-bottom: 0;
                    }

                    .uncollapse {
                        margin-left: 0;
                    }
                }

                &.filters {
                    border: 0px;
                }

                &.actions {
                    border: 0px;
                    line-height: 45px;
                    font-weight: 300;

                    .actionswrapper {
                        text-align: right;
                    }
                }

                &.list {
                    border: 0px;

                    h2 {
                        font-weight: 700;

                        span {
                            font-weight: 300;
                            font-size: 20px;
                            margin-left: 10px;
                        }
                    }

                    .sortwrapper {
                        text-align: right;
                        line-height: 60px;
                        font-weight: 700;

                        select {
                            font-weight: 300;
                            border: 0px;
                            background: $white;
                        }
                    }

                    .noresult {
                        display: block;
                        background: $gray-100;
                        border-radius: 30px;
                        font-size: 25px;
                        font-weight: 300;
                        padding: 50px;
                        margin: 30px 0px;

                        .small {
                            font-size: 50%;
                            font-weight: 100;
                        }
                    }

                    .component.agency-list {
                        margin-bottom: 50px;
                    }

                    .smartbriefactivator {
                        margin-bottom: 50px;
                    }

                    .seo_content {
                        [itemprop="mainEntity"] {
                            margin-bottom: 40px;

                            [itemprop="text"] {
                                font-size: 18px;
                                line-height: 30px;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }

            &.specialization {
                .focus-list {
                    margin-top: 20px;
                }

                .component.service-hpservices {
                    margin-top: 0;
                    margin-bottom: 60px;
                }
            }

            &.newspaper {
                margin-bottom: 60px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.qa {
                margin-bottom: 60px;
            }

            &.related {
                border: 0px;

                .container {
                    border: 0;
                }
            }
        }

        &.auth,
        &.brief {
            &.title {
                border: 0px;
            }

            &.request-form {
                border: 0px;

                form,
                .panel {
                    width: 100%;

                    .step {}
                }

                .panel {
                    .step {
                        display: block;
                    }
                }
            }
        }

        &.agency {
            .sectionopener {
                .small {
                    margin-top: 0;
                }
            }

            .component.service-browser {
                margin-top: 0px;
            }

            &.public {
                &.cover {
                    border: 0px;
                    height: 440px;
                    background-position: center;
                    margin-top: -$hnav;
                    background-size: 100%;

                    &.unclaimed {
                        height: 300px;
                    }

                    &.casehistory {
                        height: 85vh;
                        overflow: hidden;
                    }
                }

                &.brand {
                    border: 0px;

                    .container {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        border-top: 1px solid $white;
                        border: 0.5px solid $gray-300;
                        margin-top: -75px;
                        margin-bottom: 50px;
                        background-color: $white;
                        border-radius: 10px;
                    }

                    .row_brand {
                        >div {
                            display: flex;
                            align-items: center;

                            .logowrapper {
                                width: 100px;
                                height: 100px;
                                margin: 0;

                                .logofallback {
                                    line-height: 95px;
                                    font-size: 60px;
                                }

                                &.premium {
                                    padding: 0;

                                    .logofallback {
                                        border: 2px solid $white;
                                        line-height: 90px;
                                        color: $accent !important;
                                    }
                                }

                                &.casehistory {
                                    position: relative;

                                    img {
                                        width: auto;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translateX(-50%) translateY(-50%);
                                    }
                                }
                            }

                            h1 {
                                font-size: 45px;
                                font-weight: 700;
                                letter-spacing: -1px;
                                margin: 0 0 0 20px;
                                color: $gray-900;
                                text-shadow: 0 2px 0 $white;

                                &::after {
                                    display: none;
                                }
                            }

                            .badges-container {
                                position: absolute;
                                height: 20px;
                                width: auto;
                                bottom: 0;
                                left: 135px;

                                .badges.horizontal {
                                    gap: 5px;

                                    i::before,
                                    p {
                                        color: $gray-600;
                                        line-height: 20px;
                                        font-size: 12px;
                                        font-weight: 100;
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }

                    .row_nav {
                        border-bottom: 0.5px solid $gray-200;
                        box-shadow: 0 2px 0px $white;

                        .internalnav {
                            list-style-type: none;
                            margin: 0;
                            padding: 0 0 0 30px;
                            display: flex;
                            align-content: center;
                            align-items: center;
                            justify-content: space-between;
                            text-align: center;
                            width: 100%;

                            li {
                                padding: 10px 0;

                                a {
                                    color: $gray-900;
                                    font-size: 16px;
                                    font-weight: 300;
                                }

                                .col_action {
                                    align-self: end;
                                    text-align: right;
                                    display: flex;
                                    justify-content: right;

                                    .button,
                                    .morewrapper {
                                        display: inline-block;
                                        margin-left: 10px;
                                        white-space: nowrap;

                                        &:first-child {
                                            margin-left: none;
                                        }
                                    }

                                    .button {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }

                    .row_badges {
                        border-bottom: 0.5px solid $gray-200;
                        box-shadow: 0 2px 0px $white;
                        background-color: $gray-100;

                        .badges-container {
                            padding-top: 25px;
                            padding-bottom: 25px;
                        }
                    }

                    .badges-container {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        gap: 10px;
                        width: 100%;

                        &.squared {
                            justify-content: space-around;
                        }

                        .badges {
                            &.horizontal {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                gap: 10px;

                                img {
                                    width: 35px;
                                    aspect-ratio: 1/1;
                                    height: 35px;
                                }

                                i {
                                    &::before {
                                        font-size: 25px;
                                    }
                                }

                                p {
                                    font-size: 18px;
                                    padding: 0 10px;
                                    margin-bottom: 0;
                                }
                            }

                            &.squared {
                                width: 31%;
                                background-color: $white;
                                border-radius: 10px;
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                align-items: center;
                                padding: 10px;
                                box-shadow: 0 0 5px $white;

                                img {
                                    height: 45px;
                                    width: 45px;
                                }

                                p {
                                    color: $gray-500;
                                    font-size: 15px;
                                    font-weight: 600;
                                    text-align: center;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    .row_info {
                        margin-top: 30px;
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 35px;

                        .description {
                            padding: 0 30px;

                            h3 {
                                font-size: 24px;
                                font-weight: 700;
                            }

                            div {
                                margin-bottom: 30px;
                            }
                        }

                        br {
                            display: block;
                            content: "";
                            margin-top: 0.5em;
                            line-height: 190%;
                            vertical-align: top;
                        }

                        .claim {
                            padding: 0 30px;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 36px;
                            margin-bottom: 40px;
                        }

                        .claimprofile {
                            font-size: 14px;
                            padding: 10px 10px 10px 20px;
                            display: inline-block;
                            border: 0.5px solid $gray-300;
                            border-radius: 30px;
                            margin-top: 20px;
                        }

                        .contact {
                            border-left: 0.5px solid $gray-300;

                            form.landingpage {
                                position: static;
                                padding: 0 15px;
                                box-shadow: none;
                            }
                        }

                        ul.agency-info {
                            list-style-type: none;
                            text-align: left;
                            margin: 30px;
                            padding: 0;

                            li {
                                padding: 0px;
                                line-height: 55px;
                                font-size: 16px;
                                display: flex;
                                justify-content: space-between;
                                border-bottom: 0.5px solid $gray-300;

                                &.mapwrapper {
                                    display: block;
                                    padding: 0;
                                    border-bottom: none;

                                    #map {
                                        height: 240px;
                                        width: 100%;
                                        border-radius: 10px 10px 0 0;
                                        z-index: 1;
                                    }
                                }

                                span,
                                a {
                                    &.key {
                                        font-weight: 300;
                                        white-space: nowrap;

                                        &.small {
                                            font-size: 14px;
                                        }
                                    }

                                    &.value {
                                        text-align: right;
                                        font-weight: 700;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        margin-left: 10px;
                                        display: block;
                                        width: 100%;
                                    }
                                }

                                &:last-child {
                                    border-bottom: none;
                                }

                                ul.values {
                                    width: 60%;
                                    padding-left: 10px;

                                    li {
                                        width: 100%;
                                        padding: 0;

                                        .value {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.traits {
                    border: 0px;

                    .container {
                        padding-bottom: 0px;

                        .row {
                            margin-bottom: 40px;

                            &:empty {
                                margin-bottom: 0;
                            }
                        }

                        h2 {
                            margin-bottom: 10px;
                            font-weight: 700;
                            font-size: 32px;

                            &.small {
                                font-weight: 700;
                                font-size: 24px;
                                margin-bottom: 10px;
                                text-align: left;
                            }
                        }

                        img {
                            width: 100%;
                        }

                        .video-container {
                            iframe {
                                display: block !important;
                            }
                        }

                        .traits-container {
                            .single-traits-container {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                min-height: 100px;

                                p {
                                    margin-bottom: 0;
                                    font-size: 20px;
                                    font-weight: 300;
                                }
                            }

                            i {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 100%;
                                height: 70px;
                                width: 70px;
                                aspect-ratio: 1/1;
                                margin-right: 20px;
                                border: 0.5px solid $gray-300;

                                &:before {
                                    content: "\f06c";
                                    font-size: 30px;
                                    font-family: "Font Awesome 5 Pro";
                                    font-weight: 100;
                                    display: inline;
                                }

                                &.formazione-continua::before {
                                    content: "\f672";
                                }

                                &.risultati-garantiti::before {
                                    content: "\f648";
                                }

                                &.next-gen-technologies::before {
                                    content: "\f135";
                                }

                                &.work-life-balance::before {
                                    content: "\f4bc";
                                }

                                &.boutique-agency::before {
                                    content: "\f4be";
                                }

                                &.sostenibilita::before {
                                    content: "\f4bf";
                                }

                                &.societa-benefit::before,
                                &.impegno-sul-territorio::before {
                                    content: "\f4cb";
                                }

                                &.young-talents::before {
                                    content: "\f0e7";
                                }

                                &.diversity-inclusion::before {
                                    content: "\f0ac";
                                }

                                &.partner-a-lungo-termine::before {
                                    content: "\f4c6";
                                }
                            }
                        }
                    }
                }

                &.certification {
                    border: 0px;

                    .container {
                        padding-top: 0px;
                        padding-bottom: 0px;

                        .listswrapper {
                            .component {
                                .swiper-button-prev {
                                    left: 0;
                                }

                                .swiper-button-next {
                                    right: 0;
                                }

                                .tile {
                                    position: relative;

                                    div.content {
                                        h4 {
                                            font-weight: 400;
                                            font-size: 18px;
                                            line-height: 20px;
                                        }
                                    }

                                    div {
                                        a {
                                            text-transform: capitalize;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .switcherswrapper {
                        padding-top: 0px;
                        padding-bottom: 10px;
                        justify-content: left;
                        display: flex;

                        &:empty {
                            padding-bottom: 0;
                        }

                        .certificationswitcher {
                            display: inline-block;
                            margin-right: 30px;
                            font-size: 17px;
                            font-weight: 300;
                            color: $gray-400;
                            cursor: pointer;

                            &.lonestar {
                                font-weight: 700;
                                text-align: left;
                                font-size: 32px;
                                color: $gray-900 !important;
                            }

                            &:hover {
                                color: $accent;
                            }

                            &.selected {
                                color: $gray-900;
                                font-weight: 700;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    .swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
                        bottom: 10px;
                    }
                }

                &.services {
                    border: 0px;
                    padding: 0;

                    .container {
                        margin-top: 30px;
                        padding-bottom: 40px;
                        margin-bottom: 40px;
                    }

                    .component.service-percentage {
                        margin-top: 50px;
                    }

                    .component.service-table {
                        margin: 0;
                        box-shadow: none;
                        border: 0.5px solid $gray-300;
                    }
                }

                &.competitors {
                    border: 0px;
                    padding: 0;

                    .container {
                        padding-bottom: 40px;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        .container {
                            border-bottom: 0;
                        }
                    }

                    .agency-list {
                        margin-top: 0;

                        .agency-list-wrapper {
                            padding: 15px;
                            margin-left: -15px;
                            margin-right: -15px;
                        }
                    }
                }

                &.projects {
                    border: 0px;
                    padding: 0;

                    .container {
                        padding-bottom: 40px;
                        margin-bottom: 0px;
                    }
                }

                &.gallery {
                    border: 0px;
                    padding: 0;

                    .container {
                        padding-bottom: 40px;
                        margin-bottom: 0px;
                    }
                }

                &.awards {
                    border: 0px;
                    padding: 0;

                    .container {
                        padding-bottom: 40px;
                        margin-bottom: 40px;
                    }
                }

                &.testimonials {
                    border: 0px;
                    padding: 0;

                    .container {
                        margin-top: 40px;
                        margin-bottom: 40px;
                    }
                }

                &.credits {
                    border: 0px;
                    padding: 0;

                    .container {
                        padding-bottom: 40px;
                        margin-bottom: 40px;
                    }
                }

                &.reviews {
                    border: 0px;
                    padding: 0;

                    .container {
                        padding-bottom: 50px;
                    }
                }

                &.review {
                    border: 0px;
                    padding: 0;
                    background-color: $gray-400;
                    border-bottom: 0.5px solid $gray-200;

                    h2,
                    h3,
                    label {
                        //color:$white;
                    }

                    .button {
                        font-weight: 300;
                        border-color: $white;

                        &:hover {
                            border-color: $accent;
                        }
                    }

                    .container {
                        padding-top: 50px;
                        padding-bottom: 50px;

                        h3 {
                            font-size: 24px;
                            font-weight: 100;
                            padding-top: 30px;
                            clear: both;
                        }

                        .button.primary {
                            margin-top: 30px;
                            clear: both;
                        }

                        .button.reviewstart {
                            display: none;
                        }
                    }

                    &.closed {
                        .container {
                            .row:not(.sectionopener) {
                                display: none;
                            }

                            .button.reviewstart {
                                display: block;
                                float: right;
                            }
                        }
                    }
                }

                &.contact {
                    border: 0px;
                    padding: 0;

                    .container {
                        padding-bottom: 50px;

                        .label {
                            display: none;
                        }

                        .value {
                            font-size: 26px;
                            font-weight: 300;
                            border: 1px solid $maincolor;
                            padding: 15px;
                            display: block;
                            text-align: center;
                            border-radius: 20px;
                            color: $maincolor;
                            margin-top: 20px;

                            &:hover {
                                background: $maincolor;
                                border-color: $maincolor;
                                color: $white;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }

        &.profile {
            border: 0;

            &.body {
                border-bottom: 0.5px solid $gray-200;
                min-height: 400px;

                h2 {
                    font-size: 40px;
                    font-weight: 700;
                    letter-spacing: -1px;
                }

                .introduction {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 100;
                    margin-bottom: 30px;
                }

                form {
                    height: auto;
                    overflow: hidden;
                    padding-bottom: 120px;

                    h3,
                    h4,
                    h5,
                    p {
                        padding-left: 15px;
                        padding-right: 15px;
                    }

                    h3 {
                        padding: 40px 15px 10px;
                        clear: both;
                        font-weight: 700;
                        line-height: 40px;
                        margin: 0;
                    }

                    .command {
                        float: right;
                        cursor: pointer;
                        margin: 5px 20px;
                        padding: 0px 10px;
                        line-height: 30px;
                        background: $accent;
                        color: $white;
                        font-size: 12px;
                        border-radius: 15px;

                        &:hover {
                            background: $maincolor;
                        }

                        &.adder {
                            position: absolute;
                            bottom: 15px;
                            left: 30px;
                            background-color: $accent;
                            color: $white;
                            z-index: 2;
                            font-size: 24px;
                            line-height: 44px;
                            height: 44px;
                            border-radius: 4px;
                            margin: 0;
                            padding: 0 30px;

                            &:hover {
                                background-color: $white;
                                color: $accent;
                            }
                        }
                    }

                    .form-element {
                        >label {
                            font-weight: 300;
                        }

                        input,
                        select,
                        textarea,
                        div.ck-content {
                            border-radius: 4px !important;
                        }
                    }

                    [data-identifier="identifier"] {
                        display: none;
                    }
                }

                .profilemenu {
                    border-top: 1px solid $gray-100;
                    background-color: $gray-100;
                }

                .breadcrumb {
                    border-top: 1px solid $gray-100;
                    border-bottom: 0.5px solid $gray-200;
                    padding-top: 10px;
                    margin-bottom: 40px;
                    left: -15px;
                    position: relative;
                    width: calc(100% + 30px);
                    padding-left: 15px;

                    li {
                        font-size: 12px;
                        font-weight: 100;

                        a {
                            font-size: 12px;
                        }
                    }
                }
            }

            .preference-group {
                border-bottom: 0.5px solid #c4c4c4;
                padding-bottom: 1rem;
                margin-bottom: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }

                .preference-title {

                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 0.5rem;
                }

                .preference-subtitle {
                    font-size: 18px;
                    margin-bottom: 1rem;
                    font-weight: 100;
                }

                .form-element {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    padding: 10px 0 10px 30px;

                    label {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 16px;

                        &::after {
                            content: "";
                        }
                    }

                    .switch {
                        height: 22px;
                    }
                }
            }

            .form-element {
                #description {
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 30px;
                }
            }

            .vertical-menu {
                margin: 0;
                margin-bottom: 40px;
                padding: 0;
                list-style-type: none;

                .keynumber {
                    display: inline-block;
                    color: $white;
                    background: $green;
                    border-radius: 2px;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                }

                a.button.action.micro {
                    font-size: 10px;
                    height: 20px;
                    line-height: 20px;
                    float: right;
                }

                ul {
                    margin: 5px 0px 30px;
                    padding: 0;
                    list-style-type: none;

                    li {
                        border-radius: 4px;
                        margin-bottom: 2px;
                        padding: 0px 20px;

                        a {
                            color: $gray-600;
                            line-height: 15px;
                            padding: 5px 0;
                            font-weight: 100;
                            width: 100%;
                            display: block;

                            &.highlight {
                                color: $gray-700;
                                font-weight: 700;
                            }
                        }

                        &:hover {
                            background: $accent;

                            a {
                                color: $white;
                                text-decoration: none;
                            }
                        }

                        &.selected {
                            background: $maincolor;

                            a {
                                color: $white;
                            }
                        }
                    }
                }

                >li[data-active],
                [data-rel="providername"] {
                    border-radius: 4px;
                    margin-bottom: 2px;
                    padding: 0px 5px;

                    a {
                        color: $gray-600;
                        line-height: 30px;
                        font-weight: 100;
                        width: 100%;
                        display: block;
                    }

                    &:hover {
                        background: $accent;

                        a {
                            color: $white;
                            text-decoration: none;
                        }
                    }

                    &.selected {
                        background: $maincolor;

                        a {
                            color: $white;
                        }
                    }
                }

                [data-rel="providername"] {
                    a {
                        font-weight: 700;
                    }
                }
            }

            .actiongroup {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 30px;
                grid-auto-rows: minmax(200px, auto);
                margin-bottom: 100px;

                .action {
                    background-color: $gray-100;
                    text-decoration: none;
                    color: $gray-700;
                    padding: 30px;
                    border-radius: 20px;
                    cursor: pointer;
                    position: relative;

                    .category {
                        font-weight: 100;
                        font-size: 20px;
                        line-height: 20px;
                    }

                    .title {
                        font-weight: 700;
                        letter-spacing: -1px;
                        font-size: 30px;
                        line-height: 28px;
                        margin-top: 50px;
                        margin-bottom: 80px;
                    }

                    .activities {
                        position: absolute;
                        bottom: 30px;
                        left: 30px;
                        width: calc(100% - 60px);
                        list-style-type: none;
                        margin: 0;
                        padding: 0;

                        li {
                            float: left;
                            padding: 5px 5px;
                            font-size: 13px;
                            line-height: 13px;
                        }
                    }

                    &:hover {
                        .title {
                            color: $accent;
                        }
                    }

                    &.main {
                        background-color: $accent;
                        color: $white;

                        &:hover {
                            .title {
                                color: $accent-100;
                            }
                        }
                    }
                }
            }

            .analyticsgroup {
                margin-bottom: 60px;

                .analytics {
                    margin-bottom: 40px;

                    h3 {
                        clear: both;
                        font-weight: 700;
                        line-height: 40px;
                        margin: 0;
                    }

                    p {
                        font-weight: 100;
                        font-size: 16px;
                    }

                    &[data-rel="profile"] {
                        .content {
                            display: grid;
                            grid-template-columns: 300px auto;
                            gap: 30px;

                            .chart {
                                position: relative;
                                width: 240px;
                                height: 240px;

                                canvas {}

                                .mainnumber {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translateX(-50%) translateY(-50%);
                                    font-weight: 900;
                                    font-size: 44px;
                                    letter-spacing: -3px;
                                    color: $accent;

                                    span {
                                        font-weight: 100;
                                        font-size: 26px;
                                        margin-left: 4px;
                                    }
                                }
                            }

                            .tips {
                                h4 {
                                    font-weight: 700;
                                    font-size: 16px;
                                }

                                padding-left: 20px;
                                width: 100%;
                                list-style-type: none;

                                li {
                                    font-weight: 100;
                                    font-size: 16px;

                                    i {
                                        padding-right: 20px;
                                    }

                                    &.done {
                                        i {
                                            color: $green;
                                        }
                                    }

                                    &.pending {
                                        i {
                                            color: $red;
                                        }
                                    }

                                    &.pending {
                                        font-weight: 300;
                                    }
                                }
                            }
                        }
                    }

                    &[data-rel="branding"] {
                        .content {
                            display: grid;
                            grid-template-columns: 300px auto;
                            gap: 30px;

                            h4 {
                                font-size: 20px;
                                font-weight: 100;

                                span {
                                    font-weight: 900;
                                    font-size: 50px;
                                    letter-spacing: -3px;
                                    color: $accent;
                                    display: block;
                                }
                            }

                            .chart {
                                position: relative;
                                width: 100%;
                                height: 300px;

                                canvas {}
                            }
                        }
                    }

                    &[data-rel="expertise"] {
                        .content {
                            display: grid;
                            grid-template-columns: auto;
                            gap: 30px;

                            .chart {
                                position: relative;
                                width: 100%;
                                height: 300px;

                                canvas {}
                            }
                        }
                    }

                    &[data-rel="opportunity"] {
                        .content {
                            display: grid;
                            grid-template-columns: 300px auto;
                            gap: 30px;

                            h4 {
                                width: 300px;
                                font-size: 20px;
                                font-weight: 100;
                                height: 300px;

                                span {
                                    font-weight: 900;
                                    font-size: 50px;
                                    letter-spacing: -3px;
                                    color: $accent;
                                    display: block;
                                }
                            }

                            .chart {
                                position: relative;
                                width: 100%;
                                height: 300px;

                                canvas {}
                            }
                        }
                    }

                    &[data-rel="prospect"],
                    &[data-rel="intelligence"] {
                        .content {
                            display: grid;
                            grid-template-columns: 300px auto;
                            gap: 30px;

                            h4 {
                                width: 300px;
                                font-size: 20px;
                                font-weight: 100;

                                span {
                                    font-weight: 900;
                                    font-size: 50px;
                                    letter-spacing: -3px;
                                    color: $accent;
                                    display: block;
                                }
                            }

                            .table {
                                position: relative;
                                width: 100%;

                                table {
                                    width: 100%;

                                    th,
                                    td {
                                        padding: 4px 20px;
                                        font-size: 14px;
                                        text-align: left;
                                    }

                                    th {
                                        font-weight: 700;
                                        border-top: none;
                                        border-bottom: 2px solid $gray-700;
                                    }

                                    td {
                                        font-weight: 100;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.recipe {
            border: 0;


            ul.toctable {
                top: 110px;
                list-style-type: none;
                padding: 0 20px;
                margin: 0;

                li {
                    &:first-child {
                        font-size: 20px;
                        color: $accent;
                        font-weight: 100;
                        margin-bottom: 20px;
                    }

                    >a {
                        color: $gray-700;
                        font-weight: 100;
                        font-size: 14px;
                        line-height: 18px;
                        position: relative;
                        padding-left: 20px;
                        display: block;
                        margin-bottom: 10px;

                        &:before {
                            position: absolute;
                            content: "\f054";
                            font-size: 15px;
                            font-family: "Font Awesome 5 Pro";
                            font-weight: 100;
                            left: 0
                        }
                    }
                }
            }

            &.title {
                @include diagonalbackground();
                padding-bottom: 70px;
                position: relative;

                .titlewrapper {
                    height: 230px;

                    .titlepositioner {
                        position: absolute;
                        bottom: 0;
                        width: calc(100% - 30px);

                        h1 {
                            font-size: 42px;
                            line-height: 46px;
                            color: $accent;
                        }

                        h2 {
                            font-weight: 100;
                            font-size: 28px;
                            line-height: 40px;
                        }
                    }
                }

                &[data-rel="withwallpaper"] {
                    background-size: cover;
                    background-position: center;

                    ul.breadcrumb li {
                        color: $white;

                        a {
                            color: $white;
                        }
                    }

                    .titlewrapper {
                        height: auto;
                        margin-top: 40px;

                        .titlepositioner {
                            position: static;

                            h1 {
                                color: $white;
                                font-weight: 700;
                                font-size: 50px;
                                line-height: 54px;
                                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
                            }

                            h2 {
                                color: $white;
                                font-weight: 300;
                                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                            }
                        }
                    }
                }

                &.version_2,
                &.version_3 {
                    .titlewrapper {
                        height: auto;

                        .titlepositioner {
                            position: static;
                            width: 100%;
                        }
                    }
                }

                &.version_1 {
                    .component {
                        &.leadform {
                            .ctarationale {
                                display: block;
                                animation: pointToTop 3s ease infinite;
                                bottom: -130px;
                                left: 0px;
                                width: 100%;
                                height: auto;

                                &:after {
                                    right: auto;
                                    left: 40px;
                                    top: -10px;
                                }
                            }
                        }
                    }
                }

                &.version_2 {
                    .breadcrumb {
                        padding-bottom: 50px;
                    }

                    .component {
                        &.leadform {
                            margin-bottom: 0;

                            form {
                                margin-bottom: 0;
                                box-shadow: none;
                                border: 0.5px solid $gray-300;
                            }

                            .ctarationale {
                                display: block;
                                animation: pointToRight 3s ease infinite;
                                bottom: 50px;
                                left: -300px;
                                width: 280px;
                                height: auto;

                                &:after {
                                    right: -10px;
                                    top: 70px;
                                }
                            }
                        }
                    }
                }



                .component.focus-list.tag ul li {
                    background-color: $white;

                    &:hover {
                        background-color: $accent;
                    }
                }
            }

            &.pretitle {
                &.version_3 {
                    background-color: $accent;

                    .heroimage {
                        background-color: $maincolor;
                        background-image: url(/images/brief_03.jpg);
                        background-size: cover;
                        background-position: center -70px;
                        padding-right: 0;

                        &:after {
                            content: "";
                            position: absolute;
                            background-image: url(/images/bkg-hero.svg);
                            height: 260px;
                            aspect-ratio: 1 / 1;
                            right: 0;
                            bottom: 0;
                        }
                    }

                    .component {
                        &.leadform {
                            form {
                                background: transparent;
                                box-shadow: none;

                                .form-intro-element {
                                    .title {
                                        color: $white;
                                    }
                                }
                            }

                            .ctarationale {
                                display: block;
                                animation: pointToRight 3s ease infinite;
                                bottom: 0px;
                                left: -400px;
                                width: 380px;
                                height: auto;
                                background: $white;
                                border-right: 20px solid $white;
                                color: $accent;

                                &:after {
                                    right: -30px;
                                    top: 60px;
                                    background-color: $white;
                                }
                            }
                        }
                    }
                }
            }

            &.body {
                padding-top: 80px;
                margin-bottom: 100px;
                font-weight: 300;
                font-size: 18px;
                line-height: 30px;

                p {
                    &.callout {
                        font-size: 43px;
                        float: left;
                        width: 50%;
                        line-height: 1.2em;
                        color: $accent;
                        transform: translateX(-20%);
                        text-align: right;
                    }

                    &.citation {
                        font-size: 40px;
                        text-align: center;
                        width: 80%;
                        margin: 30px auto;
                        display: block;
                        line-height: 1.2em;
                        color: $accent;

                        &:before {
                            content: "\f10d";
                            font-size: 30px;
                            font-family: "Font Awesome 5 Pro";
                            font-weight: 900;
                            margin: 0 30px;
                            display: block;
                        }
                    }

                    >img {
                        width: 100% !important;
                        height: auto !important;
                        margin: 30px auto;
                        border-radius: 10px;
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                    }
                }

                h2 {
                    font-size: 2rem;
                    margin-top: 60px;
                    margin-bottom: 20px;
                    color: #FF6864;
                    font-weight: 700;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                hr {
                    margin-top: 3rem;
                }

                h3,
                h4 {
                    margin-top: 30px;
                    color: $accent;
                }

                .component.agency-list {
                    margin: 40px 0px;

                    .agency {
                        margin: 25px 0px;

                        h3 {
                            margin-top: inherit;
                            color: inherit;
                        }

                        .content {
                            max-height: 65px;
                        }
                    }
                }

                .brief-cta {
                    text-align: center;
                }

                table.price {
                    background: $gray-100;
                    width: 140%;
                    margin-left: -20%;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    border-radius: 10px;
                    border-color: transparent;
                    font-size: 16px;

                    tr {
                        td {
                            text-align: center;

                            &:first-child {
                                text-align: left;
                                font-weight: 700;
                                padding: 5px 20px;
                                width: 40%;
                                line-height: 20px;
                            }

                            &:nth-child(1),
                            &:nth-child(2) {
                                border-right-color: $white;
                            }

                            &:nth-child(2),
                            &:nth-child(3) {
                                width: 30%;
                            }
                        }

                        &:first-child {
                            td {
                                padding-top: 36px;
                                font-weight: 700;
                                font-size: 28px;
                                color: $accent;
                            }
                        }

                        &:nth-child(2) {
                            td {

                                &:nth-child(2),
                                &:nth-child(3) {
                                    span {
                                        display: block;
                                        background-color: $maincolor;
                                        border: 1px solid $maincolor;
                                        color: $white;
                                        border-radius: 25px;
                                        font-size: 30px;
                                        font-weight: 700;
                                        letter-spacing: -1px;
                                        line-height: 80px;
                                        width: 90%;
                                        margin: 0 auto;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            td {
                                padding-bottom: 36px;
                            }
                        }
                    }
                }

                .button {
                    border-radius: 30px;
                    margin: 20px 10px 0px;
                    background-color: $white;
                    border-color: $maincolor;
                    color: $maincolor;
                    cursor: pointer;

                    &.primary {
                        background-color: $maincolor;
                        color: $white;
                    }

                    &:hover {
                        background-color: $accent;
                        color: $white;
                    }
                }
            }

            &.list {
                .tile.vertical.recipe {
                    width: 30%;
                    margin: 0 1.5% 30px;
                    background: $gray-100;
                    border-radius: 20px;
                    float: left;

                    div.image {
                        width: 100%;
                        height: 120px;
                        background-size: cover;
                        border-radius: 10px 10px 0px 0px;

                        img {
                            display: none;
                        }
                    }

                    div.content {
                        max-height: 150px;
                        overflow: hidden;
                        padding: 0px 30px;

                        h2 {
                            span {
                                margin: 0;
                                font-weight: 700;
                                font-size: 30px;
                                line-height: 34px;
                            }

                            margin: 20px 0px;
                            overflow: hidden;
                            line-height: 20px;
                        }

                        h3 {
                            font-size: 18px;
                            line-height: 30px;
                            font-weight: 300;
                            height: 60px;
                            overflow: hidden;
                        }

                        p {
                            font-size: 14px;
                            line-height: 28px;
                            font-weight: 300;
                            height: 180px;
                            overflow: hidden;
                        }
                    }

                    div.action {
                        padding: 30px;
                    }
                }
            }
        }

        &.landingpage {
            border: 0px;
            padding-bottom: 50px;

            &.jumbotron {
                border-left: 10px solid $accent;
                min-height: 500px;

                .container {
                    z-index: 2;
                    position: relative;

                    h1 {
                        margin-bottom: 50px;
                    }

                    h2 {
                        color: $gray-600;
                        font-weight: 100;
                    }
                }

                canvas {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                &#coverimage {
                    background-position: top left;
                    background-size: cover;

                    h1,
                    h2 {
                        color: $white;
                    }
                }


            }

            .action {
                margin-left: 0;
                margin-top: 30px;
            }

            p,
            li {
                font-size: 20px;
                font-weight: 100;
            }

            ul {
                margin: 50px 0;
                padding: 0;
                list-style-type: none;

                li {
                    padding-left: 40px;
                    position: relative;
                    margin-bottom: 20px;

                    &:after {
                        content: "\f560";
                        font-family: "Font Awesome 5 Pro";
                        color: $maincolor;
                        position: absolute;
                        font-size: 26px;
                        top: 0;
                        left: 0;
                    }
                }

                &.linear {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 30px;

                    li {
                        position: relative;
                        padding-bottom: 40px;
                        margin-bottom: 0px;

                        &:after {
                            content: "\f00c";
                            font-weight: 100;
                            color: $accent;
                        }

                        span {
                            position: absolute;
                            display: block;
                            color: $accent;
                            bottom: 0;
                        }
                    }
                }
            }

            .highlight {
                position: relative;
                background-color: $accent;
                color: $white;
                padding: 40px 30px 30px 180px;
                border-radius: 15px;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 30px;

                p {
                    font-weight: 300;
                }

                img {
                    position: absolute;
                    left: 20px;
                    top: 40px;
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                }

                &.right {
                    text-align: right;
                }
            }

            h2 {
                font-style: normal;
                font-weight: 300;
                font-size: 2.5em;
                width: 90%;
                line-height: 1.2em;
                letter-spacing: -0.02em;

                strong {
                    color: $accent;
                }
            }

            .third {
                margin: 50px 0;
            }

            .component.testimonial-list .tile.horizontal.testimonial {
                div.profile {

                    h4,
                    h5 {
                        white-space: normal;
                        overflow: visible;
                        text-overflow: initial;
                        height: auto;
                    }
                }

                div.content {
                    padding: 15px;
                    text-align: left;
                }
            }

            &.onecolumn {

                h1,
                h2,
                h3,
                h4 {
                    width: auto;
                }
            }
        }

        &.recipe,
        &.taxonomy,
        &.serp {

            .component.leadform {
                margin: 40px 0;

                &.float {
                    margin: 40px 40px 40px 0;
                    max-width: 50%;
                    float: left;
                }

                form {
                    position: static;

                    .form-element {
                        padding: 10px 5px;
                        width: 50%;

                        &.checkbox,
                        &.form-intro-element {
                            width: 100%;
                        }
                    }


                    input[type="submit"] {
                        width: 100%;
                    }

                }

                &.illustrated {
                    form.closed {
                        padding: 30px;
                        padding-top: 170px;

                        &:after {
                            background-position: center;
                            left: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.correlated {
            border: 0;
            padding-bottom: 60px;
        }
    }
}

footer {
    position: relative;
    background: $gray-100;

    .row {
        border-bottom: 0.5px solid $gray-200;

        div {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        &.first {
            position: relative;
            padding-top: 10px;
            padding-bottom: 10px;

            .logo {
                height: 40px;
            }

            button.scrollup {
                position: absolute;
                border: 0;
                width: 60px;
                height: 60px;
                background: $accent;
                border-radius: 30px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
                font-size: 30px;
                font-weight: 100;

                &:hover {
                    background: $maincolor;
                }
            }
        }

        &.verticals {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;
                display: flex;

                li {
                    flex: auto;
                    text-align: center;

                    a {
                        color: $accent;
                        text-transform: uppercase;
                        font-size: 12;
                        letter-spacing: 2px;
                    }
                }
            }
        }

        &.second {
            h3 {
                font-size: 18px;
                margin-bottom: 20px;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    float: left;
                    width: 25%;
                    padding: 5px 10px 5px 0px;

                    a {
                        color: $black;
                        font-size: 14px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-weight: 300;
                    }
                }
            }
        }

        &.third {
            a {
                color: black;
                display: block;
                width: 100%;
                text-align: center;
            }
        }

        &.fourth {
            border-bottom: 0;
            text-align: center;
            font-weight: 300;
            font-size: 14px;

            span,
            a {
                color: black;
                display: inline-block;
                padding-right: 10px;
                border-right: 0.5px solid $black;
                margin-right: 10px;

                &:last-child {
                    border-right: 0;
                }
            }
        }

        &.fifth {
            >div {
                padding: 0;
            }

            span {
                font-size: 10px;
                color: black;
                display: inline-block;
                padding-right: 10px;
                border-right: 0.5px solid $black;
                margin-right: 10px;

                &:last-child {
                    border-right: 0;
                }
            }

            border-bottom: 0;
            text-align: center;
            font-weight: 300;
            font-size: 14px;
            padding-bottom: 40px;
        }
    }
}

/* Switch styling*/

.switch {
    height: 150px;
    border-radius: 150px;
    position: relative;
    aspect-ratio: 2;
    border: 1px solid $white;
}

.switch input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.switch div:before,
.switch div:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 150px;
    pointer-events: none;
    transition: all 0.35s ease-in-out;
}

.switch div:before {
    top: 0;
    min-height: 100%;
    min-width: 100%;
    background-color: $gray-200;
    transition-delay: 0.2s;
}

.switch div:after {
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    background-color: #fff;
    height: calc(100% - 2px);
    aspect-ratio: 1;
}

.switch input[type="checkbox"]:checked~div:after {
    left: calc(50% + 2px);
    background-color: $white;
}

.switch input[type="checkbox"]:checked~div:before {
    background-color: $green;
}

/*Switch styling*/

@import "mediaquery_10000_xlarge";
@import "mediaquery_1200_large";
@import "mediaquery_992_medium";
@import "mediaquery_768_small";
@import "mediaquery_576_xsmall";