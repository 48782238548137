// Typography
$font-family-sans-serif: 'Saira',
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
// Transitions
$transitionSpeed: 0;
// Colors
$purewhite: #FFFFFF;
$white: #FFFFFF;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$temporaryborder: $red;
$maincolor: #10406C;
$maincolor-100: #97caf9;
$black: #3C3C3B;
$accent: #FF6864;
$accent-900: #FF7C79;
$accent-800: #ff8c8a;
$accent-100: #ffdfde;
$premium: #00ADEE;
$gray-100: #F7F7F7;
$gray-150: #e2e2e2;
$gray-200: #C4C4C4;
$gray-900: #3C3C3B;
// Sizes
$hnav: 80px;

@mixin maxlines($numlines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $numlines;
    -webkit-box-orient: vertical;
}

@mixin diagonalbackground() {
    background-size: 10px 10px;
    background-image: repeating-linear-gradient(45deg, $gray-300 0, $gray-300 0.8px, #fff 0, #fff 50%);
}

@keyframes highlight {
    0% {
        box-shadow: 0px 0px 10px $gray-100;
    }

    50% {
        box-shadow: 0px 0px 30px $accent;
    }

    100% {
        box-shadow: 0px 0px 10px $gray-100;
    }
}


@keyframes pulse {
    0% {
        color: $gray-100;
    }

    20% {
        color: $accent;
    }

    80% {
        color: $accent;
    }

    100% {
        color: $gray-100;
    }
}


@keyframes pointToTop {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes pointToRight {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-30px);
    }

    100% {
        transform: translateX(0);
    }
}