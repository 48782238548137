// Variables
@import 'variables';

@media only screen and (min-width : 1200px) {
    body {
        //border-top:5px solid blue;
        &:before {
            //content:'xlarge';
            position:absolute;
            top:0;
            left:30px;
            background:blue;
            color:white;
            z-index:10;
        }
        main {
            section {
                &.agency {
                    &.public.brand {
                        .internalnav {
                            li {
                                .col_action {

                                    > .button {
                                        display: block;
                                    }
                                    li {
                                        &.moved {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
