$horizontalheight: 207px;

.component.agency-list {
    &.tile-vertical {
        margin-top: 50px;
    }

    &.tile-vertical-small:not(.advertising-zone) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }

    &.tile-horizontal {
        margin-bottom: 100px;
    }

    width: 100%;

    .tile.agency {
        display: block;
        background: $white;
        border-radius: 15px;
        border: 2px solid $purewhite;

        cursor: pointer;

        &:hover {
            background: linear-gradient($gray-100, $white);
            cursor: pointer;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            color:$gray-700;
        }

        .keynumbers {
            position: absolute;
            display: table;
            font-size: 12px;
            font-weight: 500;

            div {
                display: table-cell;
                width: 40px;
                padding: 0 10px 0 20px;
                background-repeat: no-repeat;
                background-size: 20px;
                background-position: left center;
                line-height: 35px;
                white-space: nowrap;
                font-weight: 100;

                &.projects {
                    background-image: url(/images/icons/projects.svg);
                }

                &.minsize {
                    background-image: url(/images/icons/minsize.svg);
                }

                &.reviews {
                    background-image: url(/images/icons/reviews.svg);
                }

                &.employees {
                    background-image: url(/images/icons/employees.svg);
                }
            }
        }

        .focuses {
            h5 {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }

            ul {
                margin-top: 10px;
                height: 55px;
                max-height: 55px;
                overflow: hidden;

                li {
                    display: inline-block;
                    max-width: 160px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 21px;
                    height: 22px;
                    font-size: 12px;
                    margin-bottom: 0;
                }
            }
        }

        &.vertical {
            border:0.5px solid $gray-300;
            border-radius:5px;
            text-align: center;

            a {
                display: block;
                padding: 30px 30px 80px 30px;
            }

            &.small {
                a {
                    padding: 30px;
                }
            }

            .focuses {
                height: 120px;

                ul {
                    text-align: center;
                }
            }

            .logowrapper {
                margin: 0 auto;
            }

            .keynumbers {
                width: 100%;
                height: 60px;
                bottom: -3px;
                left: 0;
                border-radius: 0 0 30px 30px;
                border-top: 0.5px solid $gray-300;
                padding: 0 15px 0 35px;

                div {
                    line-height: 60px;
                    padding: 0 10px 0 26px;
                    text-align: left;
                    text-shadow: 0px 1px 0px $purewhite;
                }
            }
        }

        &.horizontal {
            position: relative;
            height: $horizontalheight;
            border-radius: 10px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            padding-top: 0px;
            padding-bottom: 0px;

            a {
                display: block;
                padding: 0px;
            }

            &.selected {
                border-color: $accent;

                .selector {
                    &:after {
                        content: '\f14a';
                        color: $accent;
                    }
                }
            }

            .keynumbers {
                bottom: 10px;
                right: 10px;
            }

            .focuses {
                overflow: hidden;
                height: 60px;

                .focus-list {
                    height: 60px;
                    float: left;
                    max-width: 84%;

                    h5 {
                        display: none;
                    }

                    ul {
                        margin-top: 18px;
                        height: 25px;
                    }
                }

                .closure {
                    height: 60px;
                    float: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: 300;
                    line-height: 60px;
                }
            }

            $selectorvisibility: none;
            $selectorheight: $horizontalheight - 4px;

            $selectorwidth: 0px;
            $refwidth: 100%;

            @if $selectorvisibility =='block' {
                $selectorwidth: 70px;
                $refwidth: calc(100% - 70px);
            }

            .selector {
                position: absolute;
                top: 0;
                left: 0;
                width: $selectorwidth;
                border-right: 0.5px solid $gray-200;
                height: $selectorheight;
                float: left;
                cursor: pointer;
                display: $selectorvisibility;

                &:after {
                    content: '\f0c8';
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 100;
                    color: $gray-300;
                    font-size: 30px;
                    line-height: 30px;
                    width: 30px;
                    height: 30px;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: inline-block;
                    position: absolute;
                }
            }

            a {
                margin-left: $selectorwidth;
                width: $refwidth;

                &:hover {
                    text-decoration: none;
                }
            }

            .row {
                &.row1 {
                    height: $horizontalheight - 60px;
                    position: relative;
                    border-bottom: 0.5px solid $gray-200;
                    color: $gray-700;
                    text-decoration: none;

                    h3 {
                        font-size: 24px;
                        font-weight: 700;
                        padding: 30px 0px 0px;

                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 100%;
                            display: inline-block;
                        }
                    }

                    .content {
                        font-weight: 300;
                        padding: 0px;

                        &:after {
                            display: none;
                        }
                    }

                    .togglepreferred {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }

                }

                &.row2 {
                    height: 60px;
                    overflow: hidden;

                    .address {
                        display: inline;
                        padding: 0;
                        height: 60px;
                        line-height: 60px;
                        font-size: 14px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding-left: 10px;

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: url(/images/icons/city.svg) no-repeat 0px 4px;
                        }
                    }

                    .actions {
                        text-align: right;

                        .action {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }

        img[itemprop="logo"] {
            border-radius: 50%;
        }

        h2 {
            position: relative;
            height: 100px;
            margin: 0;

            span {
                position: absolute;
                left: 0px;
                width: 100%;
                font-size: 35px;
                line-height: 35px;
                font-weight: 700;
                letter-spacing: -1px;
                margin: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .address {
            display: table;
            width: 100%;
            font-size: 16px;
            font-style: italic;
            line-height: 24px;
            font-weight: 300;
            height: 24px;

            p {
                width: 50%;
                display: table-cell;
                padding-right: 5px;

                &:first-child {
                    text-align: right;
                }

                &:last-child {
                    text-align: left;
                }
            }
        }

        .content {
            position: relative;
            font-size: 14px;
            font-weight: 300;
            line-height: 23px;
            padding: 10px 0px 20px;
            margin-bottom: 20px;
            max-height: 85px;
            overflow: hidden;
        }

        .closure {
            font-size: 12px;
            font-weight: 100;
        }

        a.button {
            margin: 20px auto 0;
        }

        &.small {
            border-radius: 5px;
            background-color: $white;
            border: 0.5px solid $gray-300;

            h2 {
                height: 80px;

                span {
                    font-size: 26px;
                }
            }

            .content {
                padding: 0;
                margin: 0;
                max-height: 70px;
                @include maxlines(3);
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .component.agency-list {

        &.tile-vertical-small:not(.advertising-zone) {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }

        .tile.agency.small {

            .logowrapper {
                width: 70px;
                height: 70px;
            }

            h2 {
                span {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .component.agency-list {
        &.tile-vertical-small:not(.advertising-zone) {
            grid-template-columns: repeat(1, 1fr);
            gap: 15px;
        }
    }
}