.component.recipe-list {
    &.tile-vertical {}

    width: 100%;

    .tile.vertical.recipe {
        position: relative;
        background: $gray-100;
        border-radius: 20px;
        width: 100% !important;
        margin: 0 !important;

        div.cta {
            height: 70px;
            width: 100%;
            padding: 15px 30px;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            color: $gray-700;
            vertical-align: middle;
            display: table-cell;
            position: relative;
        }

        div.image {
            width: 100%;
            height: 110px;
            background-size: cover;
            border-radius: 20px;

            img {
                display: none;
            }
        }

        div.content {
            overflow: hidden;
            padding: 0px 30px;
            height: 190px !important;
            max-height: 190px !important;

            h2 {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                margin: 20px 0px;
                color: $accent;
                height: 82px;
                @include maxlines(3);
            }

            p {
                font-size: 14px;
                line-height: 22px !important;
                font-weight: 300 !important;
                height: 75px !important;
                @include maxlines(4);
            }
        }

        div.action {
            padding: 30px;
        }
    }

    &.tile-mosaic {
        margin-top: 0px;

        .recipe-list-mosaic {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }
    }

    &.tile-newspaper {
        margin-top: 0px;

        .recipe-list-newspaper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;

            .tile {
                text-decoration: none;
                color: $black;
                position: relative;
                background: $gray-100;
                border-radius: 10px;
                width: 100% !important;
                margin: 0 !important;

                div.image {
                    width: 100%;
                    height: 110px;
                    background-size: cover;
                    border-radius: 10px 10px 0 0;
                    border-radius: 10px 10px 10px 55px;

                    img {
                        display: none;
                    }
                }

                div.content {
                    overflow: hidden;
                    padding: 0px 30px;

                    h2 {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 20px;
                        margin: 20px 0px;
                        color: $black;
                        text-align: left;
                    }

                    p {
                        display: none;
                    }
                }

                &:first-child {
                    grid-column: 1 / span 2;
                    grid-row: 1 / span 2;

                    div.image {
                        width: 100%;
                        height: 330px;
                        border-radius: 10px 10px 0px 55px;
                    }

                    div.content {
                        h2 {
                            font-weight: 500;
                            font-size: 26px;
                            line-height: 32px;
                        }

                        p {
                            display: block;
                            font-size: 16px;
                            line-height: 26px;
                            font-weight: 300;
                            margin: 20px 0;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    &.tile-correlated {
        .tile {
            text-decoration: none;
            color: $black;
            position: relative;
            background: $white;
            border: 0.5px solid $gray-300;
            border-radius: 10px;
            padding: 25px;

            div.image {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-size: cover;
            }

            div.content {
                overflow: hidden;

                h2 {
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 25px;
                    margin: 30px 0px 0;
                    color: #FF6864;
                    text-align: left;
                    height: 75px;
                    max-height: 75px;
                }
            }
        }
    }
}

@media only screen and (max-width : 991px) {
    .component.recipe-list {
        &.tile-mosaic {
            .recipe-list-mosaic {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media only screen and (max-width : 575px) {
    .component.recipe-list {
        &.tile-mosaic {
            .recipe-list-mosaic {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}