
@import 'variables';

.step {
    display:none;
    max-width:0;
    padding:30px;
    background:$gray-100;
    border-radius:20px;
    margin-bottom:50px;
    position:relative;

    h4 {
        font-weight:900;
        font-size:30px;
        line-height:40px;
        text-align: center;
        padding: 0 20%;
    }
    .stepdescription {
        clear:both;
        font-weight:300;
        font-size:20px;
        line-height:32px;
        text-align: center;
        padding: 0 10%;
        margin-bottom:50px;
        .small {
            padding-top:30px;
            display:block;
            font-size:16px;
            font-weight:300;
            line-height:20px;
        }
    }
    &.active {
        display:block;
        max-width:100%;
    }

    .intro {
        list-style-type:none;
        display:flex;
        padding:0;
        margin:0;
        li {
            margin:30px;
            text-align:center;
            position:relative;
            //width:33.3%;
            background-image: url(/images/jumbotron_advertising@2x.svg);
            background-size: 80%;
            background-position-x: 5%;
            background-position-y: -120%;
            background-repeat:no-repeat;
            img {
                width:80%;
                height:auto;
                margin:0 10% 40px;
                border-radius:50%;
                max-width: 240px;
            }
            p {
                font-size: 24px;
                line-height: 30px;
                font-weight:300;
            }
        }
    }
    .disclaimer {
        font-size: 16px;
        line-height: 20px;
        font-weight:300;
        display:block;
        text-align:center;
    }
    .button.maincta {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        margin:40px 0px;
        color:$white;
        background:$accent;
        border-color:$accent;
        font-size: 32px;
        line-height: 60px;
        height: 60px;
        border-radius: 30px;
        padding: 0 30px;
        &:hover {
            background:$maincolor;
            border-color:$maincolor;
        }
    }
    .stepmovement {
        clear:both;
        height:60px;
        padding-top:30px;
        margin-top:30px;
        border-top:0.5px solid $gray-200;
        position:relative;
        .button:disabled {
            opacity:0.5;
        }
        .button.prev {
            float:left;
        }
        .button.next {
            float:right;
            border-color:$accent;
            background:$accent;
            color:$white;
            &:hover {
                border-color:$maincolor;
                background:$maincolor;
            }
            &.disabled {
                border-color:$gray-200;
                background:$gray-200;
            }
        }
        ul {
            padding:0;
            margin:0;
            list-style-type:none;
            display:block;
            position:absolute;
            top:17px;
            left:50%;
            transform:translateX(-50%);
            li {
                float:left;
                margin:20px;
                font-size:12px;
                font-weight:300;
                color:$gray-500;
                &.selected {
                    color:$maincolor;
                    font-weight:700;
                }
            }
        }
    }
}

@media only screen and (max-width : 991px) {
    .step {
        h4 {
            padding: 0;
            line-height: 1.1em;
        }
        .intro li  {
            margin:30px 5px;
            img {
                height: auto;
            }
            p {
                font-size: 18px;
                line-height: 1.1em;
            }
        }
    }
}
@media only screen and (max-width : 767px) {
    .step .stepmovement ul {
        li {
            font-size: 0;
            border: 1px solid $accent;
            width: 10px;
            height: 10px;
            margin: 20px 5px;
            border-radius: 5px;
            &.selected {
                background-color: $accent;
            }
        }
    }
}
@media only screen and (max-width : 575px) {
    .step {
        padding:15px;
        .intro {
            display: block;
            li  {
                width: 100%;
                img {
                    width: 50%;
                }
            }
        }
    }
}