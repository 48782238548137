// Variables
@import "variables";

@media only screen and (max-width: 991px) {
    body {

        //border-top:5px solid yellow;
        &:before {
            //content:'medium';
            position: absolute;
            top: 0;
            left: 30px;
            background: yellow;
            color: gray;
            z-index: 10;
        }

        header {
            .container-fluid {
                div.ctawrapper {
                    display: none;
                    background: $white;
                    padding: 15px;
                    position: absolute;
                    top: $hnav;
                    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
                    border-radius: 0 0 20px 20px;

                    >ul {
                        display: block;

                        >li {
                            &.dropdownwrapper {
                                width: 100%;
                                height: auto;

                                .button.dropdownenabler {
                                    width: 100%;
                                    display: block;
                                    border-radius: 0;
                                    max-width: none;
                                    height: auto;
                                    margin-top: 0;
                                    font-size: 20px;
                                    padding: 0;
                                    font-weight: 100;
                                }

                                .dropdownscroller {
                                    margin: 20px 0;

                                    .dropdown {
                                        position: static;
                                        padding: 0;
                                        box-shadow: none;
                                        grid-auto-flow: unset;
                                        grid-template-columns: repeat(3, 1fr);

                                        >li {
                                            &:first-child {
                                                grid-column: 1 / span 3;
                                                margin-top: 0;
                                                padding: 0;

                                                p.big {
                                                    font-size: 18px;
                                                    width: 100%;
                                                }
                                            }

                                            h3 {
                                                font-size: 16px;
                                                font-weight: 100;

                                                &:after {
                                                    height: 1px;
                                                }
                                            }

                                            p {
                                                font-size: 13px;
                                                @include maxlines(5);
                                            }
                                        }
                                    }
                                }
                            }

                            &.auth {
                                width: 100%;

                                a {
                                    padding: 25px 0px 0px 50px;

                                    span {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }

                div.searchwrapper {
                    width: 65%;
                }
            }
        }

        main {
            section {
                &.jumbotron {
                    background-position: center right -120px;
                    background-size: 50%;
                    min-height: auto;

                    h1 {
                        font-size: 36px;
                        width: 100%;
                    }

                    .dida {
                        width: 100%;
                        font-size: 28px;
                        line-height: 34px;
                    }

                    .component.search-bar {
                        margin-top: 40px;

                        form {
                            margin-right: 0;
                        }
                    }

                    .component.focus-list.tag {
                        margin-right: 0;

                        ul {
                            max-height: 105px;
                            margin-top: 20px;
                        }
                    }

                    .component.focus-list.search {
                        width: 100%;
                    }

                    .ctabrief {
                        margin-right: 0;

                        h3 {
                            font-size: 20px;
                        }

                        a.button {
                            position: relative;
                            margin: 15px 0px 0px 0px;
                            top: auto;
                            right: auto;
                        }
                    }
                }

                &.taxonomy {

                    &.focus,
                    &.specialization {
                        &.title {
                            .count-results {
                                margin-left: 0;
                            }
                        }
                    }
                }

                &.whoweare {}

                &.services {
                    padding: 40px 0px;

                    .component.focus-list {
                        grid-template-columns: repeat(3, 1fr);
                    }
                }

                &.newspaper {
                    .component.recipe-list.tile-newspaper {
                        .recipe-list-newspaper {
                            grid-template-columns: repeat(2, 1fr);

                            .tile:first-child {
                                grid-column: 1/span 2;
                                grid-row: 1/span 1;
                            }
                        }
                    }
                }

                &.businessintelligence {
                    padding: 40px 0px;
                }

                &.agencies {
                    padding: 40px 0px;
                }

                &.resources {
                    padding: 40px 0px;
                }

                &.agency {
                    .sectionopener {
                        h2 {
                            font-size: 30px;
                        }
                    }

                    &.public.cover {
                        background-size: cover;
                    }

                    &.public.contact {
                        .container {
                            .value {
                                font-size: 14px;
                                padding: 20px 5px;
                                margin: 0;
                            }
                        }
                    }

                    &.public.brand {
                        .row_info {
                            .description {
                                margin-bottom: 30px;
                            }

                            .claimprofile {
                                display: none;
                            }
                        }

                        .row_brand>div .button-container {
                            flex-direction: row;
                            justify-content: flex-end;
                        }

                        .badges-container {
                            padding: 20px 0;
                        }
                    }
                }

                &.landingpage {
                    &.jumbotron {
                        h2 {
                            font-size: 1.5em;
                        }
                    }
                }

                &.recipe {
                    &.title {
                        &.version_1 {
                            .component.leadform {
                                margin-bottom: 0;
                                .ctarationale {
                                    position: static;
                                    bottom: 20px;
                                }
                            }
                        }

                        &.version_2 {
                            .component.leadform {
                                .ctarationale {
                                    position: static;
                                    width: 100%;
                                    animation: pointToTop 3s ease infinite;

                                    &:after {
                                        right: 50%;
                                        top: -10px;
                                    }
                                }
                            }
                        }
                    }

                    &.body {
                        padding-top: 40px;

                        .component.toctable {
                            margin-bottom: 40px;
                        }

                        table.price {
                            tr {
                                &:first-child {
                                    td {
                                        font-size: 17px;
                                        line-height: 20px;
                                    }
                                }

                                &:nth-child(2) {
                                    td {

                                        &:nth-child(2),
                                        &:nth-child(3) {
                                            span {
                                                font-size: 20px;
                                                line-height: 40px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        footer {
            .row.second ul li {
                width: 50%;
            }
        }

        &.landing {
            &.onecolumnalternate {
                section.jumbotron {
                    min-height: 500px;
    
                    .titlepositioner {
                        max-width: unset;
                        h1 {
                            font-size: 3em;
                        }
    
                        h2 {
                            font-size: 1.4em;
                        }
                    }
                }
            }
        }
    }

    .sectionopener {
        h1 {
            font-size: 28px;
            line-height: 34px;
        }

        h2 {
            font-size: 24px;

            &.small {
                font-size: 22px;
            }
        }

        p {
            padding: 0;
            font-size: 1.2em;
            line-height: 1.5em;
        }
    }

    .component {
        &.agency-list {
            .tile.agency.horizontal {
                .row.row1 {
                    .logowrapper {
                        width: 90px;
                        height: 90px;

                        .logofallback {
                            font-size: 60px;
                            line-height: 81px;
                        }
                    }

                    h3 {
                        padding-top: 15px;
                        padding-left: 0;
                    }

                    .content {
                        padding-left: 0;
                        margin-bottom: 0;
                        font-size: 13px;
                        line-height: 15px;
                    }
                }
            }
        }

        &.search-bar {
            .searchResultWrapper {
                width: 100%;
                padding: 60px 15px 30px;

                .searchresult {
                    ul {
                        li {
                            a {
                                line-height: 30px;
                                font-size: 14px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                margin: 0;
                                padding: 0;

                                .logowrapper {
                                    margin: 0px 10px 0px 0px;

                                    .logo {
                                        transform: translateX(-50%) translateY(-50%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.service-browser {
            ul {
                &.service {
                    background: $white;
                    width: 100%;

                    &.focused {
                        transform: translateX(-100%);
                    }
                }

                &.specialization {
                    background: $white;
                    width: 100%;
                    transform: translateX(100%);

                    li {
                        padding-left: 30px;
                    }

                    &.focused {
                        transform: translateX(0%);
                    }
                }

                &.focus {
                    background: $white;
                    width: 100%;
                    transform: translateX(100%);

                    li {
                        padding-left: 30px;
                    }
                }

                &.specialization,
                &.focus {
                    li {
                        &:first-child {
                            padding-left: 50px;

                            .srvicback {
                                width: 30px;
                                height: 30px;
                                cursor: pointer;
                                position: absolute;
                                position: absolute;
                                left: 20px;
                                top: 35px;

                                &:after {
                                    content: "\f0a8";
                                    font-size: 20px;
                                    font-family: "Font Awesome 5 Pro";
                                    font-weight: 100;
                                    color: $accent;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    line-height: 30px;
                                }

                                &:hover {
                                    &:after {
                                        color: $gray-400;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.advertising-zone.advertising-zone-hpjumbotron {
            .tile.advertising {
                width: 170px;
                height: 180px;
                border-radius: 10px 10px 10px 30px;

                a {
                    .image {
                        height: 148px;
                        border-radius: 10px 10px 0 25px;
                    }

                    .title {
                        font-size: 12px;
                    }
                }

                &:nth-child(1) {
                    transform: translateX(-10%) translateY(-5%);
                }

                &:nth-child(2) {
                    transform: translateX(16%) translateY(-25%);
                }

                &:nth-child(3) {
                    transform: translateX(0%) translateY(-45%)
                }
            }
        }

        &.business-intelligence-graph.graph-hp_bi {
            display: block;

            .cell.searched,
            .cell.offered {
                .graph {
                    display: block;

                    .name {
                        text-align: left;
                    }
                }
            }
        }

        &.testimonial-list {
            .tile.horizontal.testimonial {
                display: block;

                .image {
                    margin: 0 auto;
                }

                div.profile {
                    text-align: center;

                    h5 {}
                }
            }
        }

        &.resource-list {
            display: block;

            >ul {
                >li {
                    .resource.tile {
                        display: block;
                        position: relative;
                        overflow: hidden;
                        height: auto;

                        .image {
                            float: left;
                            margin-right: 0;
                        }

                        .content {
                            float: left;
                            width: 70%;
                            padding-left: 15px;

                            a.link {
                                font-size: 16px;
                            }
                        }
                    }

                    &:first-child {
                        line-height: 30px;
                        height: 30px;
                    }
                }

                &.nav {
                    display: none;
                }

                &.latest {
                    width: 100%;
                }

                &.popular {
                    width: 100%;

                    .resource.tile {
                        .content {
                            width: 100%;
                        }
                    }

                    li:first-child {
                        display: none;
                    }
                }
            }
        }

        &.review-list {
            &.tile-vertical {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    h2 {
        font-size: 1.4rem;
    }

    ul.breadcrumb {
        li {
            font-size: 14px;
            max-width: 33%;
            display: inline-block;
            margin-right: 30px;

            a,
            &:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                margin: 0;
            }

            a {
                width: 100%;
            }

            &:after {
                position: absolute;
            }
        }
    }

    ul.filterset {
        li:not(first-child) {
            margin: 5px 5px 10px 5px;
            width: calc(100% - 10px);

            select {
                width: 100%;
            }
        }
    }
}