
.component.subscription-status {
    &.subscription-cta {
        border:1px solid #D9EECD;
        background-color: #D9EECD;
        padding:15px;
        border-radius: 4px;
        margin-bottom: 30px;
        color:#4D5748;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        p {
            margin: 0;
        }
    }

    .status {
        display: block;
        text-align: center;
        margin: 0 auto 30px;
        span {
            background-color: $gray-100;
            border-radius: 8px;
            font-size: 20px;
            font-weight: 200;
            padding: 10px 30px;
            display: inline-block;
            strong {
                font-weight: 900;
                color:$accent
            }
        }
    }
    .product {
        .description {
            font-size: 18px;
            font-weight: 200;
        }
        .prices {
            .price {
                background-color: $gray-100;
                border-radius: 8px;
                padding:30px 0;
                text-align: center;
                h3 {
                    font-size: 16px;
                    font-weight: 300;
                    padding-top: 0;
                }
                a {
                    position: relative;
                    margin: 10px 0px;
                    color: #FFFFFF;
                    background: $accent;
                    border: 1px solid $accent;
                    font-size: 32px;
                    line-height: 60px;
                    height: 60px;
                    border-radius: 30px;
                    padding: 0 30px;
                    &:hover {
                        background-color: $maincolor;
                        border: 1px solid $maincolor;
                    }
                    .priceval {
                        font-weight: 900;
                    }
                }
                > span {
                    display: block;
                    font-size: 14px;
                    font-weight: 300;
                    color:$gray-500;
                }
            }
        }
    }
    .reasonwhy {
        h3 {
            font-weight: 700;
        }
        h4 {
            font-weight: 500;
        }
        p {
            font-size: 18px;
            font-weight: 200;
        }
    }
}