// Variables
@import "variables";

@media only screen and (max-width: 767px) {
    body {

        //border-top:5px solid orange;
        &:before {
            //content:'small';
            position: absolute;
            top: 0;
            left: 30px;
            background: orange;
            color: white;
            z-index: 10;
        }


        header {
            .container-fluid {
                div.logo {
                    a {
                        width: 40px;
                        overflow: hidden;
                        img {
                            height: 48px;
                        }
                    }
                }

                div.searchwrapper {
                    .component.search-bar {
                        .searchResultWrapper {
                            height: 85vh;
                        }
                    }
                }
            }
        }

        &.www {
            header {
                .container-fluid {
                    div.logo {
                        a {
                            width: 48px;
                        }
                    }
                }
            }
        }
        
        &[data-type="homepage"] {
            header:not(.scrolled) {
                .container-fluid {
                    div.logo {
                        a {
                            // overflow:visible;
                        }
                    }
                }
            }
        }

        &.landing {
            &.onecolumn {
                header.landing {
                    .logo {
                        background-size: 120px;
                    }
                }
    
                section.jumbotron {
                    min-height: 350px;
                    padding-top: 90px;
                    padding-left: 0;
                    padding-right: 0;
    
                    .titlepositioner {
                        text-align: center;
                        h1 {
                            font-size: 2.0em;
                            line-height: 1.1em;
                            margin-bottom: 10px;
                        }
                        h2 {
                            font-size: 1.1em;
                            line-height: 1.1em;
                            margin-bottom: 0px;
                        }
                    }
                }
    
                .component{
                    &.leadform.dynamic {
                        .form-element {
                            > label {
                                font-size: 20px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }

        main {
            section {
                &.jumbotron {
                    min-height: unset;
                    background-position: top 70px center;
                    background-size: 220px;
                    padding-top: 300px;

                    h1 {
                        font-size: 30px;
                        line-height: 34px;
                    }

                    .dida {
                        margin-top: 30px;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    ul {
                        padding-left: 0;
                        margin-top: 20px;

                        li {
                            font-size: 1.1em;
                            line-height: 1.3em;
                            margin-bottom: 10px;
                        }
                    }
                }

                &.agency {
                    .sectionopener {
                        h2 {
                            font-size: 24px;
                        }
                    }

                    &.public.cover {
                        height: 300px;
                    }

                    &.public.brand {
                        .row_nav {
                            .internalnav {
                                border-bottom: 0;
                                width: 50%;
                                position: absolute;
                                right: 20px;
                                top: -90px;

                                li {
                                    padding: 0;

                                    .goto {
                                        display: none;
                                    }

                                    .col_action {
                                        >.button {
                                            display: none;
                                        }

                                        li {
                                            &.moved {
                                                display: block;
                                            }

                                            &.btnwrapper {
                                                border-bottom: none;
                                                padding: 4px 0;
                                            }

                                            a,
                                            .button {
                                                display: block;
                                                line-height: 35px;
                                                margin: 0;
                                                height: 35px;
                                            }
                                        }

                                        .contact {
                                            font-size: 12px;
                                            margin-left: 8px;
                                            padding: 0 12px;
                                            line-height: 30px;
                                            height: 30px;
                                        }
                                    }
                                }
                            }
                        }

                        .row_brand {
                            margin-bottom: 20px;

                            >div {
                                h1 {
                                    font-size: 1.8em;
                                    line-height: 1.1em;
                                }

                                .logowrapper,
                                .logowrapper.premium {
                                    width: 80px;
                                    height: 80px;

                                    .logofallback {
                                        line-height: 70px;
                                        font-size: 50px;
                                    }
                                }

                                .badges-container {
                                    left: 115px;
                                    padding: 0;
                                }
                            }

                            .ratingwrapper {
                                display: none;
                            }
                        }

                        .row_info {
                            margin-top: 40px;

                            .tile.rating {
                                justify-content: left;
                                padding: 0 45px 20px;
                            }
                        }
                    }

                    &.public.certification {
                        .switcherswrapper {
                            .certificationswitcher {
                                font-size: 16px;
                            }
                        }
                    }

                    &.public.contact {
                        .container {
                            .value {
                                font-size: 14px;
                                padding: 20px 5px;
                                margin: 0;
                            }
                        }
                    }
                }

                &.landingpage {
                    &.jumbotron h1 {
                        font-size: 2.5em;
                    }

                    .first ul li {
                        padding-left: 30px;
                        font-size: 16px;

                        &::after {
                            font-size: 20px;
                        }
                    }
                }

                &.recipe {
                    padding-top: 50px;
                    &.pretitle {
                        &.version_3 {
                            .component.leadform {
                                margin-bottom: 0;
                                .ctarationale {
                                    position: static;
                                    width: calc(100% - 60px);
                                    animation: pointToTop 3s ease infinite;
                                    margin: 0 30px 50px;
                                    &:after {
                                        right: 50%;
                                        top: -10px;
                                    }
                                }
                            }
                            .heroimage {
                                display: none;
                            }
                        }
                    }

                    &.title {
                        &[data-rel="withwallpaper"] {
                            .titlewrapper {
        
                                .titlepositioner {
                                    width: 100%;
                                    position: static;
                                    h1 {
                                        font-size: 38px;
                                        line-height: 46px;
                                    }
        
                                    h2 {
                                        font-size: 22px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                    }

                    &.body {
                        table.price {
                            width: 100%;
                            margin-left: 0;

                            tr {
                                td {
                                    text-align: center;

                                    &:first-child {
                                        font-size: 14px;
                                        line-height: 20px;
                                    }

                                    &:nth-child(2),
                                    &:nth-child(3) {
                                        font-size: 14px;
                                        line-height: 20px;
                                    }
                                }

                                &:nth-child(2) {
                                    td {

                                        &:nth-child(2),
                                        &:nth-child(3) {
                                            vertical-align: middle;

                                            span {
                                                font-size: 16px;
                                                line-height: 16px;
                                                border-radius: 4px;
                                                padding: 5px 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ul.toctable {
                        margin-bottom: 60px;
                        li > a {
                            margin-bottom: 20px;
                        }
                    }
                }

                &.partners {
                    height: auto;
                }

                &.services {
                    .component.focus-list {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }

                &.newspaper {
                    .component.recipe-list.tile-newspaper {
                        .recipe-list-newspaper {
                            grid-template-columns: repeat(1, 1fr);

                            .tile:first-child {
                                grid-column: 1/span 1;
                                grid-row: 1/span 1;

                                div.image {
                                    height: 200px;
                                }

                                div.content {
                                    h2 {
                                        font-size: 18px;
                                        line-height: 24px;
                                    }

                                    p {
                                        font-size: 14px;
                                        line-height: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        footer {
            .row.verticals ul {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            .row.fourth span {
                border-right-color: transparent;
                margin-right: 0;
            }
        }
    }

    .smartbriefactivator {
        h2 {
            font-size: 2em;
        }

        p {
            font-size: 1.2em;
            line-height: 1.4em;
        }
    }

    .component {
        &.agency-list {
            .tile.agency.horizontal {
                .row.row1 {
                    .logowrapper {
                        width: 80px;
                        height: 80px;

                        .logofallback {
                            font-size: 40px;
                            line-height: 73px;
                        }
                    }

                    .content {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }

        &.advertising-zone.advertising-zone-hpjumbotron {
            $tilesize: 80px;
            .tile.advertising {
                width: $tilesize;
                height: $tilesize;
                border-radius: 50%;

                a {
                    .image {
                        width: $tilesize;
                        height: $tilesize;
                        border-radius: 50%;
                    }
                    .title {
                        display: none;
                    }
                }

                &:nth-child(1) {
                    transform: translateX(-10%) translateY(-145%);
                }

                &:nth-child(2) {
                    transform: translateX(80%) translateY(-295%);
                }

                &:nth-child(3) {
                    transform: translateX(170%) translateY(-420%);
                }
            }
        }
    }
}