.component.advertising-zone {
    position: relative;
    height: 0px;

    &.advertising-zone-hpjumbotron {
        .tile.advertising {
            width: 220px;
            height: 226px;
            border-radius: 10px 10px 10px 60px;
            background: $white;
            padding: 0px;
            text-align: center;
            margin-bottom: 30px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            display: block;
            position: relative;

            &:hover {
                background: $accent;
                a {
                    color: $white;
                }
            }

            a {
                color: $gray-900;

                &:hover {
                    text-decoration: none;
                }

                .image {
                    overflow: hidden;
                    height: 190px;
                    border-radius: 10px 10px 0 55px;
                    margin-bottom: 5px;
                    position: relative;

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }

                .title {
                    font-size: 14px;
                    font-weight: 700;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .caption {
                    display: none;
                    font-size: 12px;
                    font-weight: 300;
                    height: 18px;
                    line-height: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:nth-child(1) {
                z-index: 1;
                transform: translateX(-10%) translateY(-15%);
            }

            &:nth-child(2) {
                z-index: 2;
                transform: translateX(16%) translateY(-45%);
            }

            &:nth-child(3) {
                z-index: 1;
                transform: translateX(-25%) translateY(-95%);
            }

            &:hover {
                z-index: 3;
            }
        }
    }

    &.advertising-zone-hpspotlight {
        height: auto;
        margin-top: 50px;
        .mosaic {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
        }
    }

    &.advertising-zone-serpspotlight {
        height: auto;
        padding-top: 10px;
        padding-bottom: 0px;
        border-bottom: 0.5px solid $gray-200;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 991px) {
    .component.advertising-zone {

        &.advertising-zone-hpspotlight {
            height: auto;

            .mosaic {
                display: flex;
                grid-template-columns: unset;
                gap: unset;
            }
        }
    }
}