// Variables
@import 'variables';

@media only screen and (max-width : 1199px) {
    body {

        //border-top:5px solid green;
        &:before {
            //content:'large';
            position:absolute;
            top:0;
            left:30px;
            background:green;
            color:white;
            z-index:10;
        }


        header {
            .container-fluid {
                div.searchwrapper {
                    .component.search-bar {
                        .searchResultWrapper {
                            box-shadow: 0 0 10px rgba(0,0,0,.1);
                            margin-right: 0;
                            padding-top: 0px;
                            top: 80px;
                            width: 100vw;
                            left: 0;
                            position: fixed;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            overflow-y: scroll;
                            height: 50vh;
                            ul {
                                li {
                                    a {
                                        height: auto;
                                        line-height: 30px;
                                        &.agency {
                                            height: 50px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        main {
            section {
                &.jumbotron {
                    background-position: center right 20px;
                    background-size: 40%;
                    min-height: auto;
                }
                &.agency {
                    &.public.brand {
                        .internalnav {
                            li {
                                .col_action {
                                    > .button {
                                        display: block;
                                    }
                                    li {
                                        &.moved {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.landing {
            &.onecolumnalternate {
                ul.linear {
                    li {
                        font-size: 17px;
                    }
                }
            }
        }
    }
}
