.form-element {
    float: left;
    width: 100%;
    padding: 15px;

    &.couple,
    &.double {
        width: 50%;
        &.first {
            padding-right: 5px !important;
        }
        &.last {
            padding-left: 5px !important;
        }
    }

    &.triple {
        width: 33.3%;
        &.first {
            padding-right: 5px !important;
        }
        &.inner {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }
        &.last {
            padding-left: 5px !important;
        }
    }

    &.quadruple {
        width: 25%;
        &.first {
            padding-right: 5px !important;
        }
        &.inner {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }
        &.last {
            padding-left: 5px !important;
        }
    }

    &.required {
        > label {
            &:before {
                content:'*';
                color:$accent;
                padding-right: 5px;
            }
            &.explicit {
                .reasonwhy {
                    padding-left: 15px;
                    font-size: 12px;
                    font-weight: 200;
                    color:$gray-600;
                }
            }
        }
    }

    &.haserror {
        > label {
            color: red;
        }

        input,
        textarea,
        select {
            border-color:red !important;
        }
    }

    &.closer {
        position: absolute;
        border-top: 0.5px solid $gray-200;
        background-color: $white;
        width: 100%;
        text-align: right;
        padding-right: 30px;
        margin-top: 40px;
        left: 0;
        bottom: 0;

        input[type="submit"],
        .button.action.primary {
            left: auto;
            position: relative;
            transform: none;
            margin: 0px 0px;
            color: $white;
            background: $accent;
            border-color: $accent;
            font-size: 24px;
            line-height: 44px;
            height: 44px;
            border-radius: 22px;
            padding: 0 30px;
            border-radius: 4px !important;

            &:hover {
                background-color: $white;
                border-color: $white;
                color: $accent;
            }
        }
    }

    &.emailforced {
        input[type="text"] {
            width: 70%;
            float: left;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            &.domain {
                width: 29%;
                float: left;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                line-height: 60px;
                height: 60px;
                padding: 0px 20px 0px 30px;
                font-size: 20px;
                position: relative;
            }
        }

        .at {
            float: left;
            position: relative;
            width: 1%;

            &:before {
                content: "@";
                position: absolute;
                top: 15px;
                left: -15px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                font-size: 20px;
                text-align: center;
                z-index: 2;
                background-color: $maincolor;
                color: $white;
                border-radius: 50%;
            }
        }
    }

    &.piva {
        position: relative;

        &:before {
            content: "IT-";
            position: absolute;
            width: 55px;
            height: 60px;
            bottom: 15px;
            left: 15px;
            line-height: 60px;
            background: $gray-200;
            text-align: center;
            border-radius: 10px 0px 0px 10px;
        }

        input {
            padding-left: 65px !important;
        }
    }

    label {
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 2px;
        line-height: 20px;
        position: relative;
        display: block;
        width: 100%;

        &:after {
            content: "\f059";
            font-size: 14px;
            font-family: "Font Awesome 5 Pro";
            color: $accent;
            position: absolute;
            font-weight: 100;
            top: 0px;
            right: 0px;
        }

        &.mute {
            &:after {
                content: "";
            }

            &:hover {
                .reasonwhy {
                    display: none;
                }
            }
        }

        &.explicit {
            .reasonwhy {
                display: block;
                width: 100%;
                box-shadow: none;
                background: transparent;
                position: static;
                padding: 0px;
            }

            &:after {
                content: "";
            }
        }

        .reasonwhy {
            display: none;
            position: absolute;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: $gray-600;
            width: 50%;
            padding: 30px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            background: $white;
            right: 0;
            top: 0;
            z-index: 2;
            min-width: 250px;

            span {
                font-weight: 700;

                &.ko {
                    color: $red;
                }

                &.ok {
                    color: $green;
                }
            }
        }

        &:hover {
            .reasonwhy {
                display: block;
            }
        }
    }

    &.checkbox,
    &.radio {
        input {
            margin-top: 5px;
            width: 50px;
            float: left;
        }

        input + label {
            width: calc(100% - 50px);
            padding-right: 50px;
            float: left;
            font-weight: 200;
            line-height: 25px;
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    input[type="date"],
    input[type="file"],
    input[type="password"],
    select,
    textarea,
    div.ck-content {
        width: 100%;
        font-size: 15px;
        font-weight: 300;
        line-height: 50px;
        height: 50px;
        padding: 10px;
        border-radius: 5px;
        border: 0.5px solid $gray-200;
        background: $white;

        &:focus {
            border-color: $accent;
            box-shadow: 0 2px 0px 0 $accent;
            outline: none;
        }

        &::placeholder {
            color: $gray-200;
        }

        &[readonly],
        &[disabled] {
            background-color: $white;
            opacity: 0.8;
            background-size: 10px 10px;
            background-image: repeating-linear-gradient(45deg, $gray-200 0, $gray-200 1px, $white 0, $white 50%);
        }
    }

    div.ck-content {
        height: inherit;
        border-radius: 4px !important;
    }

    textarea {
        font-size: 14px;
        height: 300px;
        line-height: 24px;
    }

    .preview {
        min-height: 150px;
        overflow: hidden;
        background: $gray-100;
        border-radius: 10px 10px 0px 0px;
        text-align: center;

        img {
            width: 100%;
            position: relative;
            top: 50%;
            // transform: translateY(-50%);
            transform: translateY(0);
        }
    }

    .form-element-option {
        position: relative;
        width: 100%;

        input[type="checkbox"],
        input[type="radio"] {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            float: left;
        }

        label {
            float: left;
            display: inline-block;
            font-weight: 100;
            font-size: 14px;
            width: auto;
            line-height: 24px;
            padding-left: 12px;

            &:after {
                display: none;
            }
        }

        &.traits {
            display: flex;
            justify-content: space-between;
            height: auto;
            margin: 20px 0;
            flex-direction: column;

            .checkbox-container {
                display: flex;
                flex-direction: row;
                width: 100%;
            }

            textarea {
                margin: 0 10px;
                height: 100px;
                width: auto;
            }
        }
    }

    .griddable {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    $paddingdz: 20px;

    @keyframes dzhover {
        0% {
            background-color: #ff68643d;
        }

        50% {
            background-color: #ffffff;
        }

        100% {
            background-color: #ff68643d;
        }
    }

    .dropzone {
        height: 200px;
        border-radius: 10px;
        border: 1px dashed $gray-200;
        overflow: hidden;
        padding: $paddingdz;
        position: relative;

        .dz-default {
            .dz-button {
                width: 100%;
                line-height: 200px - 2 * $paddingdz;
                background: transparent;
                border: 0;
                color: #ccc;
                font-weight: 100;
            }
        }

        &.dz-drag-hover {
            animation-name: dzhover;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        .dz-preview {
            .dz-details {
                font-weight: 100;
            }

            .dz-error-message {
                position: absolute;
                bottom: 0;
                background: red;
                width: 100%;
                left: 0;
                padding: 5px 20px;
                color: white;
                font-weight: 100;
            }

            .dz-success-mark,
            .dz-error-mark {
                position: absolute;
                top: 20px;
                left: 20px;
                width: 30px;

                svg {
                    width: 100%;

                    path {
                        fill: $green;
                    }
                }
            }

            .dz-error-mark {
                svg {
                    path {
                        fill: $red;
                    }
                }
            }

            &.dz-error {
                .dz-success-mark {
                    display: none;
                }

                .dz-error-mark,
                .dz-error-message {
                    display: block;
                }
            }

            &.dz-success {
                .dz-success-mark {
                    display: block;
                }

                .dz-error-mark,
                .dz-error-message {
                    display: none;
                }
            }

            &:not(.dz-error) {
                .dz-error-message {
                    display: none;
                }
            }
        }

        &.dz-started {
            .dz-default {
                .dz-button {
                    display: none;
                }
            }

            .dz-success-mark,
            .dz-error-mark {
                display: none;
            }
        }

        &.compact {
            height: 50px;
            border-radius: 0 0 10px 10px;
            border-top: 0px solid transparent;
            padding: 5px 20px;

            .dz-default {
                .dz-button {
                    line-height: 38px;
                }
            }

            .dz-preview {
                display: table;

                .dz-image {
                    display: table-cell;
                    width: 60px;

                    img {
                        height: 38px;
                        border-radius: 50%;
                    }
                }

                .dz-details {
                    display: table-cell;
                    line-height: 12px;
                    font-size: 10px;
                    vertical-align: middle;
                    width: auto;
                }

                .dz-progress {
                    display: none;
                }

                .dz-success-mark,
                .dz-error-mark {
                    top: -2px;
                    left: 25px;
                }
            }
        }
    }

    input[type="submit"] {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        margin: 40px 0px;
        color: $white;
        background: $accent;
        border: 1px solid $accent;
        font-size: 32px;
        line-height: 60px;
        height: 60px;
        border-radius: 30px;
        padding: 0 30px;

        &:hover {
            background: $maincolor;
            border-color: $maincolor;
        }
    }

    .accordion {
        .card {
            .card-header {
                padding: 0;

                button {
                    color: $gray-700;
                }
            }

            .card-body {
                padding: 0;

                .form-element {
                    label {
                        font-size: 14px;
                    }

                    &:not(:first-child) {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    &.form-intro-element {
        padding: 5px;
        width: 100%;

        .form-intro-answers {
            display: flex;
            column-gap: 10px;
            row-gap: 10px;
            flex-wrap: wrap;
            justify-content: space-between;

            .form-intro-answer {
                border: 0.5px solid $gray-300;
                border-radius: 5px;
                padding: 0 10px;
                min-width: 100px;
                white-space: nowrap;
                flex: auto;

                &.selected {
                    background: $accent;
                    border-color: $accent;
                    color: white;

                    &:hover {
                        background-color: $accent;
                    }
                }

                input {
                    float: left;
                    margin-right: 10px;
                    margin-top: 12px;
                }

                label {
                    font-weight: 300;
                    margin: 0;
                    line-height: 40px;
                    cursor: pointer;
                }

                &:hover {
                    background-color: $gray-100;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .form-element {
        &.quadruple {
            width: 50%;
        }
        label {
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &.form-intro-element {
            .form-intro-answer {
                position: relative;
                input[type="radio"] {
                    position: absolute;
                    top: 13px;
                    left: 13px;
                    margin: 0;
                }
                label {
                    padding-left: 30px;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .form-element {

        &.couple,
        &.double,
        &.triple,
        &.quadruple {
            width: 100%;
        }

        a.button.action.micro {
            display: block;
            margin-left: 0;
        }

        label {
            white-space: normal;

            .reasonwhy {
                display: block;
                width: 100%;
                box-shadow: none;
                background: transparent;
                position: static;
                padding: 10px 0px;
                white-space: normal;
            }

            &.mute {
                .reasonwhy {
                    display: none;
                }
            }

            &:after {
                content: "";
            }
        }

        .form-element-option {
            height: 40px;

            input[type="checkbox"],
            input[type="radio"] {
                width: 20px;
                height: 20px;
            }

            label {
                font-size: 14px;
            }
        }
    }
}