.component.language-list {
    &.tile-horizontal {
    }

    width: 100%;
    .tile.horizontal.language {
        background: white;
        border-radius: 10px;
        border:0.5px solid $gray-300;
        margin: 20px 0px;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        aspect-ratio: 1/1;
        div.image {
            width: 100%;
            border-radius: 10px;
            // background-color: #f7f7f7;
            width: 130px;
            height: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            font-size: 36px;
            margin-bottom: 40px;
            img {
                width: 130px;
            }
        }
        div.content {
            width: 100%;
            // max-width: 230px;
            // height: 100%;
            max-height: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            h4 {
                font-weight: 800;
                font-size: 1.2rem;
                line-height: 24px;
                margin: 0px;
                text-align: center;
                @include maxlines(2);
            }
        }

        a {
            font-weight: 100;
        }
    }
}
