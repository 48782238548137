
.component.target-list {
    &.tile-horizontal {
    }
    width: 100%;
    .tile.horizontal.target {
        border-radius: 10px;
        background-color: $maincolor;
        background-image: linear-gradient(45deg, $maincolor, $accent);
        margin:20px 0px;
        padding: 30px 40px;
        
        color:$white;
        h3 {
            font-size: 2rem;
            font-weight: 100;
            strong {
                font-weight: 700;
            }
        }
        ul {
            list-style-type: none;
            display: grid;
            grid-gap: 30px;
            grid-template-columns: repeat(2, 1fr);
            padding: 0;
            li {
                position: relative;
                padding-bottom: 40px;
                h4 {
                    margin-top:40px;
                    font-weight: 700;
                }
                p {
                    font-size: 21px;
                    font-weight: 100;
                }
                .button {
                    display: inline-block;
                    line-height: 30px;
                    height: 30px;
                    border:1px solid $white;
                    border-radius: 15px;
                    padding:0 15px;
                    cursor: pointer;
                    position: absolute;
                    bottom:0;
                    left: 0;
                    background: transparent;
                    margin: 0;
                    &:hover {
                        border:1px solid $maincolor;
                        background-color: $maincolor;
                        box-shadow: 0 0 5px $accent;
                    }
                }
            }
        }
    }
    .swiper {
        padding-top: 30px !important;
        padding-bottom: 0px !important;
        .swiper-pagination-bullets.swiper-pagination-horizontal {
            top:0;
            bottom:0px;
            display: table;
            table-layout: fixed;
            border-spacing: 10px;
            z-index: 0;
            .swiper-pagination-bullet {
                display: table-cell;
                color:$maincolor;
                background-color: $gray-100;
                line-height: 40px;
                border-radius: 15px 15px 0px 0px;
                padding:0 15px;
                font-weight: 700;
                font-size: 14px;
                width: auto;
                height: auto;
                opacity: 1;
                height: 40px;
                &.swiper-pagination-bullet-active {
                    color:$white;
                    background-color: $accent;
                }
            }
        }
    }
}

@media only screen and (max-width : 767px) {

    .component.target-list {
        .tile.horizontal.target {
            h3 {
                font-size: 1.5rem;
            }
            ul {
                grid-gap: 30px;
                grid-template-columns: repeat(1, 1fr);
                li {
                    h4 {
                        margin-top:20px;
                    }
                    p {
                        font-size: 15px;
                    }
                }
            }
        }
        .swiper {
            .swiper-pagination-bullets.swiper-pagination-horizontal {
                .swiper-pagination-bullet {
                    text-align: center;
                    font-size: 12px;
                    padding: 0;
                }
            }
        }
    }
}