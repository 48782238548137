$itemheight: 60px;
$focusitemheight: 40px;
$componentheight: 800px;

.component.service-table {
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    background: #fff;
    display: block;
    // min-height: $componentheight;
    overflow: hidden;
    padding: 30px 0 0;
    .service-row {
        display: flex;
        align-items: flex-end;
        font-size: 1rem;
    }
    .service-name {
        font-weight: 600;
        font-size: 1rem;
    }
    .specialization-name {
        font-size: 0.9rem;
        line-height: 0.9rem;
    }
    .focus-name {
        font-size: 0.9rem;
        line-height: 0.9rem;
        font-weight: 100;
    }
    .project-count {
        font-size: 0.9rem;
        font-weight: 100;
        text-align: center;
        display: none;
    }
    
    .price {
        font-size: 0.9rem;
        font-weight: 100;
        &:after {
            content: "€";
            padding-left: 4px;
        }
    }
    .ask-button {
        background-color: $maincolor;
        border-radius: 30px;
        font-size: 1rem;
        font-weight: 300;
        color: #fff;
        text-align: center;
        padding: 5px;
        cursor: pointer;
        &:hover {
            background-color: $accent;
        }
    }
    .focus-row {
        min-height: 75px;
        border-bottom: 0.5px solid $gray-200;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        &:last-child {
            border-bottom: none;
        }
    }
    .service-row {
        min-height: 50px;
        border-bottom: 0.5px solid $gray-900;
        span {
            &.project-head {
                text-align: center;
                display: none;
            }
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 0.5rem;
            display: block;
        }
    }
}
@media only screen and (max-width: 991px) {
    .component.service-table {
        padding: 15px;
    }
}
@media only screen and (max-width: 767px) {
    .component.service-table {
        .ask-button {
            font-size: 0;
            height: 30px;
            padding: 0 10px;
            &:before {
                content:"\f0e0";
                font-size: 14px;
                font-family: "Font Awesome 5 Pro";
                font-weight: 100;
                color:$white;
                line-height: 30px;
                text-align:right;
            }
        }
        .service-name {
            font-size: 1rem;
            margin-top: 50px;
        }
        .specialization-name {
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
}
@media only screen and (max-width: 575px) {
    .component.service-table {
        .single-service-container {
            .focus-row {
                .col-2 {
                    padding-right: 0;
                }
            }
        }
    }
}
