.component.agency-projects-editor {
    position: relative;
    .agency-projects-editor-element {
        margin-bottom: 20px;
        padding-bottom: 40px;
        overflow: hidden;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 0.5px solid #c4c4c4;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .agency-project-accordiontitle {
            width: 100%;
            font-size: 30px;
            font-weight: 700;
            padding: 0 15px;
            cursor: pointer;
            position: relative;
            &:after {
                content:'\f32c';
                font-family: "Font Awesome 5 Pro";
                font-weight: 100;
                color:$gray-300;
                font-size: 20px;
                line-height: 30px;
                width: 30px;
                height: 30px;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                display: inline-block;
                position: absolute;
            }
        }

        &.closed {
            height: 66px;
            .agency-project-accordiontitle {
                &:after {
                    content:'\f329';
                }
            }
        }

        .form-element {
            textarea {
                height: 120px !important;
            }
            .preview {
                display: flex;
                flex-wrap: wrap;
                column-gap: 30px;
                row-gap: 10px;
                .dz-preview {
                    height: fit-content;
                    display: flex;
                    align-items: center;
                    .dz-image {
                        margin-right: 10px;
                        img {
                            transform: translateY(0);
                            height: 65px;
                        }
                    }
                    .dz-details {
                        text-align: left;
                    }
                    .dz-success-mark,
                    .dz-error-mark {
                        display: none;
                    }
                    .dz-remove {
                        margin-left: 10px;
                    }
                }
            }
            .accordion {
                .btn-link {
                    box-shadow: none;
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }

        .existingmedia {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap:30px;
            margin-bottom: 20px;
            > div {
                border-radius: 5px;
                aspect-ratio: 1/1;
                overflow: hidden;
                position: relative;
                box-shadow: 0 0 20px rgba(0,0,0,0.08);
                .mediaremover {
                    position: absolute;
                    top:10px;
                    right:10px;
                    color:$white;
                    background-color: $accent;
                    border-radius: 14px;
                    padding: 2px 10px;
                    z-index: 2;
                    font-size: 12px;
                    cursor: pointer;
                }
                img,
                video {
                    position: absolute;
                    top:50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .component.agency-projects-editor {
        position: relative;
        .agency-projects-editor-element {
            .accordion {
                .card-body {
                    .form-element {
                        width: 100%;
                    }
                }
            }
        }
    }
}
