// Variables
@import "variables";

@media only screen and (max-width: 575px) {
    body {

        //border-top:5px solid red;
        &:before {
            //content:'xsmall';
            position: absolute;
            top: 0;
            left: 30px;
            background: red;
            color: white;
            z-index: 10;
        }

        header {
            .container-fluid {
                div.ctawrapper {

                    >ul {
                        >li {
                            &.dropdownwrapper {

                                .dropdownscroller {
                                    .dropdown {
                                        grid-template-columns: repeat(2, 1fr);

                                        >li {
                                            &:first-child {
                                                grid-column: 1 / span 2;
                                            }
                                            
                                            &:last-child {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        main {
            section {
                &.jumbotron {
                    h1 {}

                    .component.focus-list.search {
                        grid-template-columns: 1fr;
                        gap: 0;
                        height: auto;
                        border-radius: 26px;
                        margin-top: 40px;

                        .title {
                            font-size: 15px;
                            height: 40px;
                            line-height: 40px;
                        }
                        div.list {
                            border-radius: 22px;
                            height: 50px;
                            .placeholder {
                                line-height: 50px;
                            }
                        }
                    }
                }

                &.services {
                    .component.focus-list {
                        grid-template-columns: repeat(1, 1fr);

                        .tile.light.focus a div.content {
                            i.icon {
                                float: left;
                                font-size: 20px;
                                transform: translateY(2px);
                            }

                            h2 {
                                margin-top: 0px;
                                padding-left: 10px;
                                width: 80%;
                            }

                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                &.whoweare {
                    .feature {
                        margin-top: 20px;
                        margin-left: 30px;
                        margin-right: 30px;
                        width: calc(100% - 60px);
                        max-width: 80%;
                        padding-top: 70px;
                        padding-bottom: 10px;

                        &:after {
                            width: 50px;
                            height: 50px;
                            top:10px;
                            line-height: 50px;
                            font-size: 20px;
                        }
                    }

                    .corporate {
                        .feature.big {
                            &.odd,
                            &.even {
                                font-size: 18px;
                                padding:140px 30px 30px;
                                border: 0.5px solid $gray-300;
                                &::after {
                                    top:0;
                                    transform: none;
                                    height: 140px;
                                    width: 140px;
                                }
                            }
                            &.even::after {
                                left:30px;
                            }
                            &.odd::after {
                                right: 30px;
                            }
                        }
                    }
                }

                &.agency {
                    .sectionopener {
                        h2 {
                            font-size: 20px;
                        }
                    }

                    &.public.contact {
                        .container {
                            .value {
                                font-size: 11px;
                                padding: 20px 5px;
                                margin: 0;
                            }
                        }
                    }

                    &.public.brand {


                        .row_brand {
                            >div .button-container {
                                justify-content: space-around;
                                padding: 0;
                            }
                        }

                        .badges-container {
                            &.squared {
                                flex-direction: row;
                            }

                            .badges {
                                &.horizontal {
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }

                &.recipe {
                    &.title,
                    &.pretitle {
                        &.version_1,
                        &.version_2,
                        &.version_3 {
                            .component.leadform {
                                form {
                                    background: $white;
                                    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
                                    .form-intro-element .title {
                                        color:$accent;
                                    }
                                }
                                .ctarationale {
                                    display: none;
                                }
                            }
                        }
                    }
                    &.title {
                        .titlewrapper .titlepositioner {
                            h1 {
                                font-size: 28px;
                                line-height: 1.1em;
                            }

                            h2 {
                                font-size: 20px;
                                line-height: 1.1em;
                            }
                        }
                    }

                    &.body {
                        .component.agency-list {
                            width: 100%;
                            margin: 40px 0px;
                        }
                    }
                }

                &.profile {
                    .actiongroup {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }

                &.landingpage {
                    &.jumbotron {
                        h1 {
                            font-size: 2em;
                        }

                        h2 {
                            font-size: 1.5em;
                        }
                    }

                    p,
                    li {
                        font-size: 16px;
                    }

                    .first ul {
                        gap: 15px;

                        li {
                            padding: 40px 0 40px;
                            font-size: 14px;
                            text-align: center;

                            span {
                                width: 100%;
                                text-align: center;
                            }

                            &::after {
                                font-size: 20px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }

                    .highlight {
                        border-radius: 0;
                        padding: 30px;

                        img {
                            position: static;
                            width: 100px;
                            height: 100px;
                            clear: both;
                            float: none;
                            margin: 0 auto;
                            display: block;
                            margin-bottom: 30px;
                        }

                        p {
                            font-size: 20px;
                        }
                    }

                    h2 {
                        font-size: 2em;
                    }
                }

                &.recipe,
                &.taxonomy,
                &.serp {

                    .component.leadform {
                        &.float {
                            margin: 40px 0;
                            max-width: unset;
                            float: none;
                        }

                        form {

                            .form-element {
                                padding: 10px 0px;
                                width: 100%;

                                input[type="text"],
                                input[type="number"],
                                input[type="email"],
                                input[type="date"],
                                input[type="file"],
                                input[type="password"],
                                select,
                                textarea {
                                    line-height: 50px;
                                    height: 50px;
                                    border-radius: 5px;
                                }
                            }
                        }

                        &.illustrated {
                            form.closed {
                                padding: 20px;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        footer {
            .row.first button.scrollup {
                right: 20px;
            }
        }

        &.landing {
            &.onecolumnalternate {
                section.jumbotron {
                    min-height: 400px;
    
                    .titlepositioner {
                        h1 {
                            margin-top: 200px;
                            font-size: 2em;
                        }
    
                        h2 {
                            font-size: 1.2em;
                            line-height: 1.4em;
                        }
                    }
                }
            }
        }
    }

    .component {
        &.agency-list {
            .tile.agency.horizontal {
                .row.row1 {
                    .logowrapper {
                        width: 60px;
                        height: 60px;
                        transform: translateX(-10px);

                        .logofallback {
                            line-height: 53px;
                        }
                    }

                    .content {
                        font-size: 13px;
                        line-height: 21px;
                        height: 68px;
                        @include maxlines(3);
                    }
                }
            }
        }

        &.component.project-list {
            .tile.vertical.project {
                div.cover {
                    img {}
                }
            }
        }
    }
}