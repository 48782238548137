
.component.resource-list {
    box-shadow:0 0 30px rgba(0,0,0,0.1);
    border-radius:20px;
    background:$white;
    display:flex;
    margin-top:60px;
    overflow:hidden;
    > ul {
        margin:0;
        padding:0;
        list-style-type:none;
        &.nav {
            width:25%;
            z-index:2;
            border-radius:20px;
            background:$white;
            box-shadow:0 0 30px rgba(0,0,0,0.1);
            display:block;
        }
        &.latest {
            width:50%;
            position:relative;
            &.lonely {
                width:100%;
            }
            > li {
                &:first-child {
                    font-size:16px;
                }
            }
            &:after {
                content:'';
                width:0.5px;
                height:calc(100% - 80px);
                right:0px;
                top:40px;
                //background:$gray-200;
                position:absolute;
                display:block;
            }
        }
        &.popular {
            width:25%;
            > li {
                &:first-child {
                    font-size:16px;
                }
                .resource.tile {
                    .description {
                        display:none;
                    }
                    .image {
                        display:none;
                    }
                }
            }
        }
        > li {
            padding:0px 30px;
            &:first-child {
                font-weight: 500;
                font-size: 22px;
                line-height: 100px;
                height: 100px;
            }
            &:last-child {
                .resource.tile {
                    border-bottom:none;
                }
            }
            .resource.tile {
                display:flex;
                padding-bottom:15px;
                margin-bottom:15px;
                border-bottom:0.5px solid $gray-200;
                .image {
                    width:30%;
                    margin-right:20px;
                    border-radius:5px;
                    overflow:hidden;
                    img {
                        width: 100%;
                        height:auto;
                        border-radius: 10px;
                    }
                }
                .content {
                    width:100%;
                    a.link {
                        padding-right:50px;
                        position:relative;
                        font-size:20px;
                        color:$gray-900;
                        width:100%;
                        display:block;
                        &:after{
                            content: "\f061";
                            font-size: 23px;
                            font-family: "Font Awesome 5 Pro";
                            position: absolute;
                            color:$accent;
                            font-weight: 100;
                            top: 0;
                            right: 0;
                        }
                    }
                    .description {
                        padding-right:50px;
                        color:$gray-600;
                        font-weight:300;
                    }
                    .info {
                        display:block;
                        div,
                        a {
                            padding-right:20px;
                            position:relative;
                            text-overflow:ellipsis;
                            overflow:hidden;
                            white-space:nowrap;
                            max-width:50%;
                            color:$accent;
                            float:left;
                            &:after {
                                content:'';
                                position:absolute;
                                width:6px;
                                height:1px;
                                background:$gray-200;
                                right:8px;
                                top:11px;
                            }
                            &.timetoread {
                                color:$gray-400;
                                font-weight:300;
                            }
                            &:last-child {
                                &:after {
                                    display:none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width : 575px) {
    .component.resource-list {
        > ul {
            > li {
                .resource.tile {
                    .content {
                        .description {
                            height: 70px;
                            @include maxlines(3);
                        }
                    }
                }
            }
        }
    }
}