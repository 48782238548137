$searchboxheight:80px;

@keyframes rotation {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(359deg);
}
}
.component.search-bar {
    margin-top: 70px;
    position: relative;
    form {
        margin-right: 100px;
        position: relative;
        z-index: 3;

        &.searching {
            &:after {
                content:'\f110';
                display: block;
                font-size: 30px;
                font-family: "Font Awesome 5 Pro";
                font-weight: 700;
                color: $accent;
                position: absolute;
                top: 26px;
                right: 90px;
                line-height: 30px;
                text-align: right;
                animation: rotation 2s infinite linear;
            }
        }

        input.searchBox {
            width: 100%;
            height: $searchboxheight;
            line-height: $searchboxheight;
            font-size: 20px;
            padding: 0 20px;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
            background: #FFFFFF;
            border-radius: 30px;
            border: 0px;

            &::placeholder {
                font-size: initial;
                color: $gray-200;
            }

            &:focus {
                border-color: $accent;
            }
        }

        input[type="submit"] {
            position: absolute;
            top: $searchboxheight*0.1;
            right: $searchboxheight*0.1;
            height: $searchboxheight - ($searchboxheight*0.2);
            width: $searchboxheight - ($searchboxheight*0.2);
            border: none;
            border-radius: 24px;
            background-color: #FF6864;
            background-image: url(/images/icons/search.svg);
            background-repeat: no-repeat;
            background-size: 50%;
            background-position: center;
        }
    }
    .searchResultWrapper {
        &:empty {
            display: none;
        }
        z-index: 2;
        position: absolute;
        margin-right: 100px;
        background: $purewhite;
        padding: $searchboxheight 30px 30px;
        width: calc(100% - 100px);
        top: 0;//$searchboxheight - $searchboxheight / 2;
        border-radius: 24px;//0px 0px 24px 24px;
        .searchresult {
            position:relative;
            .ctabrief {
                background: $accent;
                margin: -80px -30px 0 -30px;
                padding: 80px 30px 30px;
                color:$white;
                h3, p {
                    width: 70%;
                }
                h3 {
                    font-size: 16px;
                }
                p {
                    font-size: 14px;
                    font-weight: 300;
                }
                a.button {
                    position: absolute;
                    top: 66px;
                    right: 30px;
                    &:hover {
                        border-color: $white;
                    }
                }
            }
            .noresult {
                &:before {
                    content:'\f119';
                    font-size: 50px;
                    font-family: "Font Awesome 5 Pro";
                    position: absolute;
                    font-weight: 100;
                    top: 0px;
                    left: 20px;
                    color:$gray-400;
                }
                padding-left:25%;
                p { font-weight: 300;}
            }
            h5 {
                font-size: 16px;
                margin-top:20px;
                padding-top:15px;
                border-top:0.5px solid $gray-200;
                &:first-of-type {
                    border-top:0;
                }
            }
            ul {
                list-style-type: none;
                padding-left: 0;
                li {
                    a {
                        color:$black;
                        font-size: 16px;
                        line-height: 40px;
                        font-weight: 300;
                        display: block;
                        border-radius: 20px;
                        margin:0 -15px;
                        padding:0 15px;
                        &.premium {
                            color:$premium;
                        }
                        &:hover {
                            background: $gray-100;
                            text-decoration: none;
                        }
                        .logowrapper {
                            width: 30px;
                            height: 30px;
                            border-radius: 15px;
                            margin: 5px 10px 0px 0px;
                            float: left;
                            border: none;
                            .logofallback {
                                font-size: 14px;
                                line-height: 27px;
                            }
                            .logo {
                                width: auto;
                                height: 100%;
                                border-radius: 0;
                                position: relative;
                                left: 50%;
                                top: 50%;
                                transform: translateX(-50%) translateY(-70%);
                            }
                        }
                        mark {
                            padding:0;
                            color:$accent;
                            background: transparent;
                        }
                        .secondary {
                            color:$gray-200;
                            margin-left:5px;
                            font-weight: 300;
                            font-size: 80%;
                        }
                    }
                }

                &:last-of-type {
                    border-bottom: 0px;
                }
            }
            &.suggest {

            }
        }
    }
}
