.briefsmartintro {
    flex-direction: column;
    justify-content: space-between;
    padding-top: 7% !important;
    padding-bottom: 4% !important;
    gap: 8%;
    max-width: 0;
    height: 100%;
    &.intro {
        justify-content: flex-start;
    }
    &.boxed {
        height: 80vh;
        overflow-y: scroll;
        margin-bottom: 0;
    }
    &.active {
        display: flex !important;
    }
    .steptitle {
        font-weight: 400;
        font-size: 2.3vw;
        line-height: 40px;
        text-align: center;
        padding: 0 20%;
    }
    .stepdescription {
        margin-bottom: unset !important;
        padding: 0 20% !important;
        .disclaimer {
            font-size: 2.1vw !important;
            line-height: 1 !important;
        }
        &.outro {
            font-size: 27px;
        }
    }
    .choisetitle {
        font-size: 25px;
        line-height: 35px;
        font-weight: 300;
        display: block;
        text-align: center;
        margin-bottom: unset !important;
        font-size: 2.1vw;
        &.small {
            padding: 0 20%;
        }
    }
    .buttoncontainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        .button {
            background: $accent;
            border-radius: 30px;
            border: 0;
            text-align: center;
            color: #ffffff;
            width: 60%;
            font-size: 1.3vw;
            padding: 2px;
            padding-left: 30px;
            padding-right: 30px;
            height: unset;
            line-height: unset;
            max-width: unset;
            cursor: pointer;
            &:hover {
                background-color: $maincolor;
            }
        }
    }
    input[type="text"] {
        width: 100%;
        font-size: 20px;
        padding: 0 20px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        background: #ffffff;
        border-radius: 24px;
        border: 0px;
        padding: 5px 20px;
        &:focus-visible {
            border: 1px solid $accent;
        }
    }
    .smartfrom-spacing {
        padding: 0 20%;
    }
    .selectionscrollcontainer {
        max-height: 500px;
        overflow-y: scroll;
        margin: 0 20%;
        .form-element-option {
            position: relative;
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            input[type="checkbox"] {
                width: 30px;
                height: 30px;
                margin-left: 10px;
                float: left;
            }
            input[type="radio"] {
                width: 25px;
                height: 25px;
            }

            label {
                margin-bottom: 0;
                text-align: left;
                display: inline-block;
                font-weight: 100;
                font-size: 20px;
                width: auto;
                line-height: 30px;
                padding-left: 12px;
                &:after {
                    display: none;
                }
            }
        }
    }
    .stepmovement {
        margin: 0 20%;
        width: auto !important;
        height: auto !important;
        padding-top: 10px !important;
        border: 0 !important;
        display: flex !important;
        justify-content: space-between;
        .prev {
            float: unset !important;
            border-radius: 30px !important;
            border: 1px solid $accent !important;
            text-align: center !important;
            color: $accent !important;
            font-size: 20px !important;
            padding: 20px !important;
            text-align: center !important;
            cursor: pointer !important;
            align-items: center;
            justify-content: center;
            display: flex;
            margin-left: 0px !important;
            &:hover {
                color: $maincolor !important;
                border: 1px solid $maincolor !important;
                background: unset !important;
            }
        }
        .next {
            float: unset !important;
            background: $accent !important;
            border-radius: 30px !important;
            border: 0 !important;
            text-align: center !important;
            color: #ffffff !important;
            font-size: 20px !important;
            padding: 20px !important;
            text-align: center !important;
            cursor: pointer !important;
            align-items: center;
            justify-content: center;
            display: flex;
            margin-left: 0px !important;
            &:hover {
                background-color: $maincolor !important;
            }
        }
    }
    .taglistinput {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20%;
        textarea {
            &.short {
                height: 82px;
                padding: 0 20px;
                line-height: 80px;
            }
            height: 161px;
            width: 100%;
            line-height: 1.5;
            font-size: 20px;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
            background: #ffffff;
            border-radius: 24px;
            border: 0px;
            resize: none;
            padding: 10px 20px;
            &:focus-visible {
                border: 1px solid $accent;
            }
        }
        .createtagcontainer {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            gap: 10px;
            i {
                width: 35px;
                // height: 35px;
                aspect-ratio: 1/1;
                border-radius: 100%;
                background-color: $accent;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }
        .hint {
            margin-top: 15px;
        }
        .listtitle {
            margin-top: 50px;
            width: 100%;
        }
        .listinput {
            display: flex;
            gap: 5px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .selected-tag {
            border: 1px solid $accent;
            border-radius: 30px;
            padding: 5px;
            padding-right: 5px;
            padding-left: 5px;
            display: flex;
            justify-content: space-between;
            min-width: 100px;
            padding-left: 20px;
            padding-right: 10px;
            background-color: $white;
            color: $accent;
            min-height: 35px;
        }
        .remove-tag {
            opacity: 0.3;
            position: relative;
            right: 0;
            height: 15px;
            width: 22px;
            float: right;
            cursor: pointer;
        }
        .remove-tag:hover {
            opacity: 1;
        }
        .remove-tag:before,
        .remove-tag:after {
            position: absolute;
            left: 50%;
            content: " ";
            height: 10px;
            width: 2px;
            background-color: $accent;
            top: 50%;
        }
        .remove-tag:before {
            transform: rotate(45deg);
        }
        .remove-tag:after {
            transform: rotate(-45deg);
        }
    }
    &.outro {
        max-height: 80vh;
        overflow-y: scroll;
    }
    .outro-checkboxes {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
        input[type="checkbox"] {
            position: relative;
            margin-top: 5px;
            border-radius: 100%;
        }
    }
    .button.main-cta {
        padding: 0 90px;
    }
    .input-container {
        overflow-y: scroll;
        width: 100%;
    }
}
.popUpModalContainer {
    background-color: #00000080;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1010;
    transition: all 0.3s ease-out;
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: auto;
}
.popUpModalContainer.open {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: all;
}
.popUpModal {
    width: 60vw;
    min-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f7f7f7;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.5);
    #popUpBriefModalContent {
        height: 100%;
        width: 100%;
    }
}
.close-popup-brief-modal {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: $accent;
    width: 52px;
    height: 52px;
    cursor: pointer;
    z-index: 1;
}
.close-popup-icon {
    width: 20px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}
.close-popup-icon::after {
    display: block;
    content: "";
    width: 20px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
}
.open-smart-brief {
    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .close-popup-brief-modal {
        width: 35px;
        height: 35px;
    }
    .popUpModal {
        width: 90vw;
        #popUpBriefModalContent {
            height: 80vh;
            .brief {
                height: 100%;
                .container {
                    height: 100%;
                    .briefwrapper {
                        height: 100%;
                    }
                }
            }
        }
    }
    .briefsmartintro {
        &.intro {
            justify-content: center;
        }
        .buttoncontainer {
            .button {
                width: auto;
                font-size: 0.8rem;
            }
        }
        .selectionscrollcontainer {
            .form-element-option {
                label {
                    font-size: 1rem;
                    line-height: 1.1;
                    max-width: 90%;
                }
                input[type="radio"] {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        input[type="text"] {
            font-size: 1rem;
        }
        .taglistinput {
            textarea {
                font-size: 1rem;
                line-height: 1.2;
            }
        }
        .steptitle {
            padding: 0;
            font-size: 1rem;
        }
        .stepdescription {
            .disclaimer {
                font-size: 1rem !important;
            }
        }
        .choisetitle {
            font-size: 1rem;
            margin-top: 10px;
        }
        .stepmovement {
            .prev {
                font-size: 1rem !important;
            }
            .next {
                font-size: 1rem !important;
            }
        }
    }
}
@media only screen and (max-width: 575px) {
    .briefsmartintro {
        .second-row-form {
            margin-top: -15%;
        }
        .smartfrom-spacing {
            padding: 0;
        }
        .taglistinput {
            padding: 0;
        }
        .steptitle {
            padding: 0;
        }
        .choisetitle {
            &.small {
                padding: 0;
            }
        }
        .selectionscrollcontainer {
            margin: 0;
        }
        .stepmovement {
            margin: 0 10%;
        }
    }
}
