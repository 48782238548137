
.component.project-list {
    &.tile-vertical {
    }
    
    .tile.vertical.project {
        background: white;
        border-radius: 10px;
        border:0.5px solid $gray-300;
        margin:20px 0px;
        cursor:pointer;
        div.cover {
            width: 100%;
            height: 360px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            background: $gray-100;
            img, 
            video {
                height: 100%;
                left: 50%;
                top:50%;
                position: relative;
                transform: translateX(-50%) translateY(-50%);
            }
            .customer {
                position: absolute;
                top:15px;
                left:15px;
                display: inline-block;
                padding: 0 10px;
                border-radius: 10px;
                line-height: 20px;
                font-size: 14px;
                color:$gray-900;
                background-color: $gray-100;
                font-weight: 100;
            }

            .mediacount {
                position: absolute;
                top:15px;
                right:15px;
                display: inline-block;
                padding: 0 10px 0 10px;
                border-radius: 10px;
                line-height: 20px;
                font-size: 14px;
                color:$gray-900;
                background-color: $gray-100;
                font-weight: 100;
                &:before {
                    content:'\f083';
                    font-family: "Font Awesome 5 Pro";
                    padding-right: 5px;
                }
            }
            .description {
                position: absolute;
                background-color:rgba(0,0,0,0.6);
                padding:20px;
                width: 100%;
                bottom:0;

                h4 {
                    font-size: 16px;
                    font-weight: 700;
                    width: 100%;
                    color:$white;
                    margin:0;
                    @include maxlines(2);
                }
            }
        }
        div.content {
            padding: 0px 20px;
            height: 65px;
            max-height: 65px;
            overflow: hidden;
            .focus-list {
                display: flex;
                h5 {
                    line-height: 65px;
                    font-weight: 700;
                    margin-right: 10px;
                    margin-bottom: 0px;
                }
                ul {
                    margin-top: 20px;
                    max-height: 26px;
                    overflow: hidden;
                }
            }
        }
    }
}

@media only screen and (max-width : 767px) {
    .component.project-list {
        .tile.horizontal.project {
            div.cover img {
                width: auto;
                height: 100%;
                left: 50%;
                position: relative;
                transform: translateX(-50%);
            }
        }
    }
}