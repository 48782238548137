.component.partnership-list {
    &.tile-horizontal {
    }
    width: 100%;
    .tile.horizontal.partnership {
        background: white;
        border-radius: 10px;
        border:0.5px solid $gray-300;
        margin: 20px 0px;
        padding: 15px;
        height: 194px;
        display: flex;
        div.image {
            width: 130px;
            height: 162px;
            max-height: 162px;
            border-radius: 10px;
            overflow: hidden;
            display: table-cell;
            background-color: $gray-100;
            min-width: 130px;
            position: relative;
            img {
                height: 162px;
                width: auto;
                transform: translateX(-50%);
                left: 50%;
                position: absolute;
            }
            i {
                font-size: 60px;
                color:$gray-300;
                width: auto;
                height: auto;
                transform: translateX(-50%) translateY(-50%);
                left: 50%;
                top:50%;
                position: absolute;
            }
        }
        div.content {
            width: 100%;
            max-width: 230px;
            height: 162px;
            max-height: 162px;
            overflow: hidden;
            padding-left: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            h4 {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                margin: 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            h5 {
                font-size: 12px;
                line-height: 18px;
                font-weight: 300;
                height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                white-space: nowrap;
                background-color: $gray-500;
                color:$gray-100;
                border-radius: 20px;
                margin-bottom: 0;
            }
            p {
                font-size: 10px;
                line-height: 16px;
                font-weight: 300;
                height: 80px;
                overflow: hidden;
                margin: 0;
            }
        }
    }
}
