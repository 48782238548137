.component.focus-list {
    &.search {
        display: grid;
        grid-template-columns: 165px auto;
        gap: 30px;
        width: 630px;
        height: 70px;
        border-radius: 40px;
        background-color: $maincolor;
        border: 5px solid $maincolor;

        div {
            height: 60px;

            &.title {
                font-weight: 300;
                font-size: 18px;
                line-height: 60px;
                padding-left: 20px;
                color: $white;
            }

            &.list {
                position: relative;
                background-color: $white;
                border-radius: 40px;
                .placeholder {
                    padding: 0 30px;
                    font-size: 1.2em;
                    font-weight: 300;
                    line-height: 60px;
                }
                ul {
                    display: none;
                    position: absolute;
                    top:0;
                    left:0;
                    margin: 0;
                    padding: 12px 15px 20px;
                    list-style-type: none;
                    width: 100%;
                    background: white;
                    border-radius: 30px 30px 20px 20px;
                    z-index: 30;
                    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
                    li {
                        font-size: 1.2em;
                        line-height: 2.1em;
                        width: 100%;
                        padding: 0 15px;
                        border-radius: 20px;
                        cursor: pointer;
                        &:hover {
                            background-color: $gray-100;
                        }
                        a {
                            display: block;
                            width: 100%;
                            color: $black;
                            text-decoration: none;
                        }
                    }
                }
                &:hover {
                    ul {
                        display: block;
                    }
                }
            }
        }

    }

    &.tag {
        h5 {
            font-weight: 300;
            font-size: 16px;
        }

        ul {
            list-style-type: none;
            text-align: left;
            margin: 30px 0 0;
            padding: 0;

            li {
                display: inline-block;
                padding: 0 15px;
                height: 36px;
                border-radius: 20px;
                font-weight: 300;
                margin-right: 10px;
                margin-bottom: 15px;
                border: 0.5px solid $black;

                a {
                    display: block;
                    width: 100%;
                    line-height: 36px;
                    font-size: 14px;
                    font-weight: 300;
                    color: $black;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:hover {
                        text-decoration: none;
                        color: $white;
                    }
                }

                &:hover {
                    border-color: $accent;
                    background: $accent;
                }

                &.small {
                    padding: 0 10px;
                    height: 30px;
                    margin-right: 7px;
                    margin-bottom: 10px;

                    a {
                        line-height: 30px;
                        font-size: 13px;
                    }
                }

                &.micro {
                    padding: 0 10px;
                    height: 26px;
                    margin-right: 7px;
                    margin-bottom: 10px;

                    a {
                        line-height: 26px;
                        font-size: 11px;
                    }
                }
            }
        }
    }


    .tile.vertical.focus {
        border-radius: 15px;
        background: $gray-100;
        border-left: 20px solid $gray-100;

        &:hover {
            border-left: 20px solid $accent;
        }

        a {
            color: inherit;
            text-decoration: none;

            div.image {
                width: 100%;
                height: 200px;
                background-size: cover;

                img {
                    display: none;
                }
            }

            div.content {
                max-height: 300px;
                overflow: hidden;
                padding: 0px 30px;

                h2 {
                    span {
                        margin: 0;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 28px;
                    }

                    margin:20px 0px;
                    height: 75px;
                    @include maxlines(2);
                }

                h3 {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 300;
                    height: 100px;
                    @include maxlines(4);
                }

                p {
                    font-size: 14px;
                    line-height: 28px;
                    font-weight: 300;
                    height: 180px;
                    overflow: hidden;
                }
            }

            div.action {
                padding: 30px;
                button {
                    margin-left: 0;
                }
            }
        }
    }

    .tile.light.focus {
        border-radius: 10px;
        background: $gray-100;
        border-left: 10px solid $gray-100;

        &:hover {
            border-left: 10px solid $accent;
        }

        a {
            color: inherit;
            text-decoration: none;

            div.content {
                overflow: hidden;
                padding: 20px;

                i.icon {
                    font-size:30px;
                    color: $accent;
                }

                h2 {
                    margin: 0;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    margin:20px 0px 10px;
                    height: 48px;
                    @include maxlines(2);
                }

                p {
                    font-weight: 300;
                }
            }

        }
    }

    &.tile-mosaic {
        margin-top: 60px;

        .focus-list-mosaic {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }
    }
}

@media only screen and (max-width : 991px) {
    .component.focus-list {
        &.tile-mosaic {
            .focus-list-mosaic {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media only screen and (max-width : 575px) {
    .component.focus-list {
        &.tile-mosaic {
            .focus-list-mosaic {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .tile.vertical.focus {
            border-left-color: $accent;

            a div.content {
                h2 {
                    span {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}