$itemheight: 60px;
$focusitemheight: 40px;
$componentheight: 800px;

.component.service-browser {
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    background: linear-gradient(
        to right,
        $white 0%,
        $white 50%,
        $gray-100 50%,
        $gray-100 100%
    );
    display: block;
    min-height: $componentheight;
    overflow: hidden;
    margin-top: 60px;
    &.service-hpservices {
        ul {
            &.service {
                > li {
                    /*
                    &:last-child {
                        > div.label {
                            background: $accent;
                            color: $white;

                            &:before {
                                border-top-color: $white;
                            }

                            &:after {
                                display: none;
                            }
                        }

                        &.selected {
                            > div.label {
                                text-shadow: 0px 0px 2px
                                    rgba(255, 255, 255, 0.9);

                                &:before {
                                    border-top-color: transparent;
                                    border-left-color: $white;
                                }
                            }
                        }
                    }
                    */
                }
            }
        }
    }
    ul {
        list-style-type: none;
        border-radius: 20px;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 0;
        left: 0;
        width: 33.333%;
        min-height: $componentheight;
        background: $white;
        box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.02);
        &.service {
            > li {
                > div.label {
                    font-weight: 700;
                    padding-left: 45px;
                    > span.icon {
                        width: 28px;
                        height: 28px;
                        position: absolute;
                        top: 0px;
                        left: 22px;
                        &:after {
                            content: "\f606";
                            font-family: "Font Awesome 5 Pro";
                            position: absolute;
                            top: 0px;
                            left: 0px;
                        }
                    }
                }
            }
        }
        &.service,
        &.specialization {
            > li {
                > div.label {
                    &:before {
                        content: "";
                        position: absolute;
                        right: 20px;
                        top: $itemheight * 0.5 - 1;
                        width: 0px;
                        height: 0px;
                        border: 3px solid transparent;
                        border-bottom: 0;
                        border-top: 4px solid $black;
                        background: transparent;
                    }
                }
            }
        }

        &.specialization,
        &.focus {
            transform: translateX(109%);
            width: 96.6%;
            display: none;
            > li {
                padding-left: 15px;
                a {
                    display: block;
                    color: $black;
                    &:hover {
                        color: $accent;
                        text-decoration: none;
                    }
                }
            }
        }
        &.focus {
            background: $gray-100;
            > li {
                height: $focusitemheight;
                > div.label {
                    line-height: $focusitemheight;
                    &:after {
                        top: $focusitemheight - 1;
                    }
                }
            }
        }
        li {
            padding: 0 30px;
            line-height: $itemheight;
            height: $itemheight;
            &:first-child {
                font-weight: 500;
                font-size: 22px;
                line-height: 100px;
                height: 100px;
                position: relative;
            }
            &.selected {
                > ul {
                    display: block;
                }
                > div.label {
                    background: $gray-150;
                    &:before {
                        border: 3px solid transparent;
                        border-right: 0;
                        border-left: 4px solid $accent;
                    }
                    &:after {
                        display: none;
                    }
                }
            }
            &:not(.selected) {
                > ul {
                    display: none;
                }
            }
            > div.label {
                position: relative;
                margin: 0px -10px;
                line-height: $itemheight + 2;
                border-radius: 5px;
                width: calc(100% + 20px);
                cursor: pointer;
                padding-left: 20px;
                font-weight: 300;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                a {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 20px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 20px);
                    height: 1px;
                    background: $gray-200;
                    left: 10px;
                    top: $itemheight;
                }
                &:hover {
                    color: $accent;
                }
            }
        }
    }
}

.component.service-percentage,
.component.service-selectable {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    ul.service {
        mark {
            color: $accent;
            padding: 0;
            background: transparent;
        }
        > li[data-role="service"] {
            > div.label {
                position: relative;
                height: 70px;
                line-height: 70px;
                font-size: 24px;
                font-weight: 700;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                padding: 0 20px 0 50px;
                z-index: 2;
                background: $white;
                cursor: pointer;

                > span.icon {
                    width: 28px;
                    height: 28px;
                    position: absolute;
                    top: 0px;
                    left: 22px;
                    &:after {
                        content: "\f606";
                        font-family: "Font Awesome 5 Pro";
                        position: absolute;
                        top: 0px;
                        left: 0px;
                    }
                }

                &:after {
                    content: "\f077";
                    position: absolute;
                    right: 30px;
                    font-size: 14px;
                    top: 0px;
                    font-family: "Font Awesome 5 Pro";
                }
            }

            &.closed {
                div.label {
                    &:after {
                        content: "\f078";
                    }
                }
                div.specialization-list-wrapper {
                    display: none;
                }

                margin-bottom: 30px;
            }

            div.specialization-list-wrapper {
                margin-top: -10px;
                padding: 40px 15px;
                background: $gray-100;
                border-radius: 10px;
                margin-bottom: 30px;
                .specialization {
                    cursor: pointer;
                    background: white;
                    border-radius: 10px;
                    padding: 15px;
                    font-size: 14px;
                    font-weight: 300;
                    width: 100%;
                    border: 2px solid $white;
                    .title {
                        font-weight: 700;
                        font-size: 18px;
                        margin-bottom: 15px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    &:hover {
                        border-color: $accent;
                        .nohover {
                            display: none;
                        }
                        .hover {
                            display: block;
                        }
                    }
                    .hover {
                        display: none;
                        ul.focus {
                            margin-top: 15px;
                            li {
                                padding: 2px 0px;

                                span.percentage {
                                    color: $accent;
                                    display: inline-block;
                                    background: transparent;
                                    min-width: 40px;
                                    font-weight: 500;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.component.service-selectable {
    ul.service {
        > li[data-role="service"] {
            position: relative;
            div.specialization-list-wrapper {
                .specialization {
                    margin-bottom: 10px;
                    position: relative;
                    .title {
                        overflow: visible;
                        text-overflow: clip;
                        white-space: normal;
                        margin-bottom: 0px;
                        z-index: 1;
                        position: relative;
                        .countfocus {
                            padding-left: 5px;
                            font-weight: 100;
                            font-size: 0.8em;
                        }
                    }
                    &:after {
                        z-index: 0;
                        content: "\f077";
                        position: absolute;
                        right: 30px;
                        font-size: 14px;
                        top: 19px;
                        font-family: "Font Awesome 5 Pro";
                    }
                    &.closed {
                        &:after {
                            content: "\f078";
                            // top: 50%;
                            // transform: translateY(-50%);
                        }
                    }
                    &:hover {
                        border-color: $white;
                    }
                    .hover {
                        display: block;
                        ul.focus {
                            margin-top: 15px;
                            li {
                                position: relative;
                                width: 100%;
                                .label {
                                    display: block;
                                    width: 100%;
                                    margin: 0;

                                    &.services {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-start;
                                        gap: 15px;
                                        label {
                                            width: 50%;
                                            height: 35px;
                                            display: flex;
                                            align-items: center;
                                        }
                                        .pricing-container {
                                            display: flex;
                                            position: relative;
                                            &.hide {
                                                display: none !important;
                                            }
                                            gap: 10px;
                                            label {
                                                strong {
                                                    font-weight: 100;
                                                }
                                                &:hover {
                                                    color: unset;
                                                }
                                                &::before {
                                                    display: none;
                                                }
                                            }
                                            input {
                                                border: 1px solid $gray-200;
                                                border-right-width: 30px;
                                                border-radius: 4px;
                                                font-size: 0.9em;
                                                font-weight: 100;
                                                padding:0 10px;
                                            }
                                            &:after {
                                                content:'€';
                                                position: absolute;
                                                color: $white;
                                                top:7px;
                                                right: 10px;
                                            }
                                        }
                                    }
                                    input[type="checkbox"] {
                                        display: table-cell;
                                    }
                                    label {
                                        padding-left: 25px;
                                        display: block;
                                        width: 100%;
                                        margin: 0;
                                        position: relative;
                                        cursor: pointer;
                                        &:hover {
                                            color: $accent;
                                        }
                                        &:before {
                                            content: "\f0c8";
                                            display: block;
                                            font-size: 15px;
                                            font-family: "Font Awesome 5 Pro";
                                            font-weight: 700;
                                            color: $gray-200;
                                            position: absolute;
                                            left: 0;
                                        }
                                        &.checked:before {
                                            content: "\f14a";
                                            color: $accent;
                                        }
                                    }
                                    a {
                                        display: block;
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .swiper-pagination {
                bottom: 7px;
            }
            .swiper-button-navigation {
                width: 15px;
                height: 30px;
                cursor: pointer;
                position: absolute;
                background: $gray-100;
                color: $gray-700;
                top: 50%;
                font-size: 10px;
                font-weight: 100;
                line-height: 30px;
                text-align: center;
                &.swiper-button-prev-unique {
                    left: -15px;
                    border-radius: 8px 0px 0px 8px;
                }
                &.swiper-button-next-unique {
                    right: -15px;
                    border-radius: 0px 8px 8px 0px;
                }
            }
            &.closed {
                .swiper-button-navigation {
                    display: none;
                }
            }
        }
    }
}
.accordion-specialization {
    &.closed {
        .hover {
            display: none !important;
        }
    }
}
@media only screen and (max-width: 575px) {
    .component.service-percentage
        ul.service
        > li[data-role="service"]
        > div.label,
    .component.service-selectable
        ul.service
        > li[data-role="service"]
        > div.label {
        font-size: 14px;
    }
}
